.check-check {
  width: 32px;
  height: 32px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  position: relative;
  align-items: center;
  border-color: #ffffff;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
  justify-content: center;
}
.check-icon1 {
  fill: #ffffff;
  width: 12px;
  height: 12px;
}
.checkroot-class-name {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
  background-color: #272727;
}
.checkroot-class-name1 {
  background-color: #272727;
}
.checkroot-class-name2 {
  background-color: #272727;
}
.checkroot-class-name3 {
  background-color: #272727;
}
.checkroot-class-name4 {
  background-color: #2684FC;
}
.checkroot-class-name5 {
  background-color: #2684FC;
}
.checkroot-class-name6 {
  background-color: #2684FC;
}
.checkroot-class-name7 {
  background-color: #2684FC;
}
.checkroot-class-name8 {
  background-color: #272727;
}
.checkroot-class-name9 {
  background-color: #2684FC;
}
.checkroot-class-name10 {
  background-color: #2684FC;
}
.checkroot-class-name11 {
  background-color: #2684FC;
}
.checkroot-class-name12 {
  background-color: #2684FC;
}
.checkroot-class-name13 {
  background-color: #2684FC;
}
.checkroot-class-name14 {
  background-color: #2684FC;
}
.checkroot-class-name15 {
  background-color: #2684FC;
}
.checkroot-class-name16 {
  background-color: #2684FC;
}
.checkroot-class-name17 {
  background-color: #2684FC;
}
.checkroot-class-name18 {
  background-color: #2684FC;
}
.checkroot-class-name19 {
  background-color: #2684FC;
}
.checkroot-class-name20 {
  background-color: #2684FC;
}
.checkroot-class-name21 {
  background-color: #2684FC;
}
.checkroot-class-name22 {
  background-color: #2684FC;
}
.checkroot-class-name23 {
  background-color: #2684FC;
}
.checkroot-class-name24 {
  background-color: #2684FC;
}
.checkroot-class-name25 {
  background-color: #2684FC;
}
.checkroot-class-name26 {
  background-color: #2684FC;
}
.checkroot-class-name27 {
  background-color: #2684FC;
}
.checkroot-class-name28 {
  background-color: #2684FC;
}
.checkroot-class-name29 {
  background-color: #2684FC;
}
.checkroot-class-name30 {
  background-color: #2684FC;
}
.checkroot-class-name31 {
  background-color: #2684FC;
}
.checkroot-class-name32 {
  background-color: #2684FC;
}
.checkroot-class-name33 {
  background-color: #2684FC;
}
.checkroot-class-name34 {
  background-color: #2684FC;
}
