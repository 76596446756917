.ofx-pre-seed-sale-container10 {
  width: 100%;
  display: flex;
  overflow: hidden;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
}
.ofx-pre-seed-sale-hero {
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
  flex-direction: column;
  justify-content: flex-start;
  background-color: #000000;
}
.ofx-pre-seed-sale-header10 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.ofx-pre-seed-sale-navbar {
  width: 100%;
  display: flex;
  z-index: 1000;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  justify-content: space-between;
}
.ofx-pre-seed-sale-link10 {
  display: contents;
}
.ofx-pre-seed-sale-branding1 {
  width: 120px;
  object-fit: cover;
  text-decoration: none;
}
.ofx-pre-seed-sale-burger-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ofx-pre-seed-sale-hamburger {
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-fourunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.3);
}
.ofx-pre-seed-sale-hamburger:hover {
  opacity: .5;
}
.ofx-pre-seed-sale-icon10 {
  width: 60px;
  object-fit: cover;
}
.ofx-pre-seed-sale-mobile-menu {
  top: 0px;
  left: 0px;
  color: var(--dl-color-gray-white);
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 32px;
  z-index: 100;
  position: fixed;
  transform: translateX(100%);
  transition: 0.5s;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--dl-color-gray-black);
}
.ofx-pre-seed-sale-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.ofx-pre-seed-sale-container11 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.ofx-pre-seed-sale-image10 {
  height: 2rem;
}
.ofx-pre-seed-sale-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ofx-pre-seed-sale-icon11 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.ofx-pre-seed-sale-nav2 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.ofx-pre-seed-sale-link11 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.ofx-pre-seed-sale-link12 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.ofx-pre-seed-sale-link13 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.ofx-pre-seed-sale-link14 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.ofx-pre-seed-sale-link15 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.ofx-pre-seed-sale-container12 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
}
.ofx-pre-seed-sale-register {
  text-decoration: none;
}
.ofx-pre-seed-sale-icon-group {
  fill: var(--dl-color-gray-white);
  display: flex;
}
.ofx-pre-seed-sale-icon13 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.ofx-pre-seed-sale-icon15 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.ofx-pre-seed-sale-icon17 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.ofx-pre-seed-sale-video10 {
  top: 0px;
  left: 0px;
  width: 100%;
  filter: opacity(0.6);
  height: 100%;
  position: absolute;
  object-fit: cover;
}
.ofx-pre-seed-sale-hero-content {
  gap: var(--dl-space-space-fiveunits);
  flex: 1;
  width: 100%;
  display: flex;
  z-index: 100;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  flex-direction: column;
  justify-content: flex-start;
}
.ofx-pre-seed-sale-header-container1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.ofx-pre-seed-sale-header11 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.ofx-pre-seed-sale-image11 {
  width: 300px;
  object-fit: cover;
  margin-bottom: var(--dl-space-space-unit);
}
.ofx-pre-seed-sale-heading10 {
  color: rgb(255, 255, 255);
  font-size: 120px;
  max-width: 790px;
  font-style: normal;
  font-family: "Fira Sans";
  font-weight: 600;
}
.ofx-pre-seed-sale-caption10 {
  color: rgb(255, 255, 255);
  font-size: 42px;
  max-width: 600px;
  line-height: 52px;
}
.ofx-pre-seed-sale-text103 {
  max-width: 600px;
}
.ofx-pre-seed-sale-container13 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.ofx-pre-seed-sale-container14 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
}
.ofx-pre-seed-sale-text107 {
  color: rgb(255, 255, 255);
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
}
.ofx-pre-seed-sale-textinput1 {
  width: 996px;
  height: 100%;
  font-size: 32px;
  padding-top: 0px;
  border-width: 0px;
  padding-left: var(--dl-space-space-unit);
  border-radius: 10px;
  padding-right: var(--dl-space-space-unit);
  padding-bottom: 0px;
}
.ofx-pre-seed-sale-container15 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.ofx-pre-seed-sale-text108 {
  color: rgb(255, 255, 255);
  font-size: 22px;
}
.ofx-pre-seed-sale-textinput2 {
  fill: var(--dl-color-gray-white);
  color: rgba(255, 255, 255, 0.69);
  width: 468px;
  height: 32px;
  border-color: rgba(0, 0, 0, 0.6);
  background-color: var(--dl-color-gray-black);
}
.ofx-pre-seed-sale-customer1 {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: 0px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
}
.ofx-pre-seed-sale-header12 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-self: center;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.ofx-pre-seed-sale-heading11 {
  color: rgb(25, 25, 25);
  font-size: 60px;
  max-width: 900px;
  font-style: normal;
  font-weight: 400;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
}
.ofx-pre-seed-sale-text111 {
  font-size: 32px;
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  font-weight: 300;
  line-height: 36px;
}
.ofx-pre-seed-sale-link16 {
  display: contents;
}
.ofx-pre-seed-sale-more1 {
  gap: 4px;
  cursor: pointer;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
}
.ofx-pre-seed-sale-more1:hover {
  opacity: 0.5;
}
.ofx-pre-seed-sale-caption11 {
  fill: var(--dl-color-primary-300);
  color: var(--dl-color-primary-300);
  font-size: 22px;
  max-width: 600px;
  font-style: normal;
  font-weight: 500;
}
.ofx-pre-seed-sale-icon19 {
  fill: var(--dl-color-primary-300);
  width: 21px;
  height: 29px;
}
.ofx-pre-seed-sale-container16 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  overflow-x: scroll;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.ofx-pre-seed-sale-container17 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.ofx-pre-seed-sale-quote10 {
  gap: var(--dl-space-space-twounits);
  height: 100%;
  display: flex;
  padding: 0px;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 0.2);
  flex-direction: column;
  border-right-width: 1px;
}
.ofx-pre-seed-sale-video11 {
  width: 286px;
  height: 498px;
}
.ofx-pre-seed-sale-text112 {
  font-size: 2rem;
}
.ofx-pre-seed-sale-author10 {
  gap: 4px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.ofx-pre-seed-sale-name10 {
  color: rgb(39, 39, 39);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}
.ofx-pre-seed-sale-location10 {
  color: rgb(39, 39, 39);
  font-size: 22px;
  font-style: normal;
  font-weight: 300;
  line-height: auto;
}
.ofx-pre-seed-sale-container18 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.ofx-pre-seed-sale-quote11 {
  gap: var(--dl-space-space-twounits);
  height: 100%;
  display: flex;
  padding: 0px;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 0.2);
  flex-direction: column;
  border-right-width: 1px;
}
.ofx-pre-seed-sale-video12 {
  width: 286px;
  height: 498px;
}
.ofx-pre-seed-sale-text113 {
  font-size: 2rem;
}
.ofx-pre-seed-sale-author11 {
  gap: 4px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.ofx-pre-seed-sale-name11 {
  color: rgb(39, 39, 39);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}
.ofx-pre-seed-sale-location11 {
  color: rgb(39, 39, 39);
  font-size: 22px;
  font-style: normal;
  font-weight: 300;
}
.ofx-pre-seed-sale-container19 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.ofx-pre-seed-sale-quote12 {
  gap: var(--dl-space-space-twounits);
  height: 100%;
  display: flex;
  padding: 0px;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 0.2);
  flex-direction: column;
  border-right-width: 1px;
}
.ofx-pre-seed-sale-video13 {
  width: 286px;
  height: 498px;
}
.ofx-pre-seed-sale-text114 {
  font-size: 2rem;
}
.ofx-pre-seed-sale-author12 {
  gap: 4px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.ofx-pre-seed-sale-name12 {
  color: rgb(39, 39, 39);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}
.ofx-pre-seed-sale-location12 {
  color: rgb(39, 39, 39);
  font-size: 22px;
  font-style: normal;
  font-weight: 300;
}
.ofx-pre-seed-sale-container20 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.ofx-pre-seed-sale-quote13 {
  gap: var(--dl-space-space-twounits);
  height: 100%;
  display: flex;
  padding: 0px;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 0.2);
  flex-direction: column;
  border-right-width: 1px;
}
.ofx-pre-seed-sale-video14 {
  width: 286px;
  height: 498px;
}
.ofx-pre-seed-sale-text115 {
  font-size: 2rem;
}
.ofx-pre-seed-sale-author13 {
  gap: 4px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.ofx-pre-seed-sale-name13 {
  color: rgb(39, 39, 39);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}
.ofx-pre-seed-sale-location13 {
  color: rgb(39, 39, 39);
  font-size: 22px;
  font-style: normal;
  font-weight: 300;
}
.ofx-pre-seed-sale-container21 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.ofx-pre-seed-sale-quote14 {
  gap: var(--dl-space-space-twounits);
  height: 100%;
  display: flex;
  padding: 0px;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 0.2);
  flex-direction: column;
  border-right-width: 1px;
}
.ofx-pre-seed-sale-video15 {
  width: 286px;
  height: 498px;
}
.ofx-pre-seed-sale-text116 {
  font-size: 2rem;
}
.ofx-pre-seed-sale-author14 {
  gap: 4px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.ofx-pre-seed-sale-name14 {
  color: rgb(39, 39, 39);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}
.ofx-pre-seed-sale-location14 {
  color: rgb(39, 39, 39);
  font-size: 22px;
  font-style: normal;
  font-weight: 300;
  line-height: 21px;
}
.ofx-pre-seed-sale-container22 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.ofx-pre-seed-sale-quote15 {
  gap: var(--dl-space-space-twounits);
  height: 100%;
  display: flex;
  padding: 0px;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 0.2);
  flex-direction: column;
  border-right-width: 1px;
}
.ofx-pre-seed-sale-video16 {
  width: 286px;
  height: 498px;
}
.ofx-pre-seed-sale-text117 {
  font-size: 2rem;
}
.ofx-pre-seed-sale-author15 {
  gap: 4px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.ofx-pre-seed-sale-name15 {
  color: rgb(39, 39, 39);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}
.ofx-pre-seed-sale-location15 {
  color: rgb(39, 39, 39);
  font-size: 22px;
  font-style: normal;
  font-weight: 300;
}
.ofx-pre-seed-sale-controls1 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: none;
  max-width: var(--dl-size-size-maxwidth);
  align-self: center;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
}
.ofx-pre-seed-sale-previous1 {
  display: flex;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  background-color: #2684FC;
}
.ofx-pre-seed-sale-icon21 {
  fill: #ffffff;
  width: 24px;
  height: 24px;
}
.ofx-pre-seed-sale-next1 {
  display: flex;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  background-color: #2684FC;
}
.ofx-pre-seed-sale-icon23 {
  fill: #ffffff;
  width: 24px;
  height: 24px;
}
.ofx-pre-seed-sale-button1 {
  border-radius: 10px;
  background-color: #2684FC;
}
.ofx-pre-seed-sale-faq1 {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 160px;
}
.ofx-pre-seed-sale-header13 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.ofx-pre-seed-sale-heading12 {
  color: #191919;
  font-size: 60px;
  font-weight: 400;
}
.ofx-pre-seed-sale-text121 {
  font-style: normal;
}
.ofx-pre-seed-sale-text122 {
  font-style: italic;
  font-family: ""Playfair Display"";
}
.ofx-pre-seed-sale-content10 {
  gap: 120px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.ofx-pre-seed-sale-column1 {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.ofx-pre-seed-sale-element10 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.ofx-pre-seed-sale-header14 {
  color: rgb(25, 25, 25);
}
.ofx-pre-seed-sale-content11 {
  color: rgb(124, 124, 128);
  width: 100%;
  display: flex;
  max-width: 560px;
  line-height: 24px;
}
.ofx-pre-seed-sale-link17 {
  text-decoration: none;
}
.ofx-pre-seed-sale-element11 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.ofx-pre-seed-sale-header15 {
  color: rgb(25, 25, 25);
}
.ofx-pre-seed-sale-content12 {
  color: rgb(124, 124, 128);
  line-height: 24px;
}
.ofx-pre-seed-sale-element12 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.ofx-pre-seed-sale-header16 {
  color: rgb(25, 25, 25);
}
.ofx-pre-seed-sale-content13 {
  color: rgb(124, 124, 128);
  width: 100%;
  display: flex;
  max-width: 560px;
  line-height: 24px;
}
.ofx-pre-seed-sale-column2 {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.ofx-pre-seed-sale-element13 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.ofx-pre-seed-sale-header17 {
  color: rgb(25, 25, 25);
}
.ofx-pre-seed-sale-content14 {
  color: rgb(124, 124, 128);
  line-height: 24px;
}
.ofx-pre-seed-sale-element14 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.ofx-pre-seed-sale-header18 {
  color: rgb(25, 25, 25);
}
.ofx-pre-seed-sale-content15 {
  color: rgb(124, 124, 128);
  line-height: 24px;
}
.ofx-pre-seed-sale-element15 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.ofx-pre-seed-sale-header19 {
  color: rgb(25, 25, 25);
}
.ofx-pre-seed-sale-content16 {
  color: rgb(124, 124, 128);
  width: 100%;
  display: flex;
  max-width: 560px;
  line-height: 24px;
}
.ofx-pre-seed-sale-get-started {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-sixunits);
  padding-right: var(--dl-space-space-sixunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
}
.ofx-pre-seed-sale-header-container2 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  z-index: 100;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.ofx-pre-seed-sale-header20 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 800px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.ofx-pre-seed-sale-heading13 {
  fill: #191919;
  color: rgb(25, 25, 25);
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
}
.ofx-pre-seed-sale-caption12 {
  color: rgb(124, 124, 128);
  font-size: 24px;
  max-width: 600px;
  text-align: center;
  line-height: 36px;
}
.ofx-pre-seed-sale-button2 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.ofx-pre-seed-sale-container23 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.ofx-pre-seed-sale-button3 {
  width: 293px;
  text-align: center;
  border-radius: 10px;
  text-decoration: none;
  background-color: #2684FC;
}
.ofx-pre-seed-sale-text128 {
  text-align: center;
}
.ofx-pre-seed-sale-button4 {
  fill: #2684FC;
  color: #2684FC;
  width: 293px;
  text-align: center;
  border-color: #2684FC;
  border-width: 1px;
  border-radius: 10px;
  text-decoration: none;
  background-color: #ffffff;
}
.ofx-pre-seed-sale-text129 {
  text-align: center;
}
.ofx-pre-seed-sale-text130 {
  fill: var(--dl-color-gray-500);
  color: var(--dl-color-gray-500);
  margin-top: var(--dl-space-space-oneandhalfunits);
}
.ofx-pre-seed-sale-image12 {
  width: 200px;
  object-fit: cover;
}
.ofx-pre-seed-sale-slides {
  gap: var(--dl-space-space-fourunits);
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-direction: column;
  padding-bottom: 0px;
}
.ofx-pre-seed-sale-slide-titles {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.ofx-pre-seed-sale-slide-title1 {
  flex: 1;
  border-color: #fdc827;
}
.ofx-pre-seed-sale-text131 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
}
.ofx-pre-seed-sale-slide-title2 {
  flex: 1;
  border-color: var(--dl-color-primary-300);
}
.ofx-pre-seed-sale-text132 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
}
.ofx-pre-seed-sale-slide-title3 {
  flex: 1;
  border-color: #00AE45;
}
.ofx-pre-seed-sale-text133 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
}
.ofx-pre-seed-sale-slide-title4 {
  flex: 1;
  border-color: #D45230;
}
.ofx-pre-seed-sale-text134 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
}
.ofx-pre-seed-sale-slider1 {
  gap: var(--dl-space-space-fiveunits);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.ofx-pre-seed-sale-slider-container {
  gap: var(--dl-space-space-fourunits);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.ofx-pre-seed-sale-slider-track-container {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.ofx-pre-seed-sale-slider-track {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.ofx-pre-seed-sale-slide1 {
  background-color: #dead1c;
}
.ofx-pre-seed-sale-image13 {
  height: 400px;
  object-fit: cover;
  aspect-ratio: 1;
}
.ofx-pre-seed-sale-content17 {
  height: 400px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.ofx-pre-seed-sale-image14 {
  width: 40px;
  margin-top: 0px;
  object-fit: cover;
  margin-bottom: var(--dl-space-space-unit);
}
.ofx-pre-seed-sale-header21 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  flex-direction: column;
}
.ofx-pre-seed-sale-heading14 {
  color: #ffffff;
}
.ofx-pre-seed-sale-caption13 {
  fill: var(--dl-color-gray-black);
  color: rgb(255, 255, 255);
  max-width: 600px;
  line-height: 24px;
}
.ofx-pre-seed-sale-link19 {
  display: contents;
}
.ofx-pre-seed-sale-more2 {
  gap: 4px;
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.ofx-pre-seed-sale-more2:hover {
  opacity: 0.5;
}
.ofx-pre-seed-sale-caption14 {
  color: rgb(255, 255, 255);
  max-width: 600px;
  font-style: normal;
  font-weight: 500;
}
.ofx-pre-seed-sale-icon25 {
  fill: #ffffff;
  width: 14px;
  height: 14px;
}
.ofx-pre-seed-sale-slide2 {
  background-color: #2684FC;
}
.ofx-pre-seed-sale-image15 {
  width: 343px;
  height: 400px;
  object-fit: cover;
  aspect-ratio: 1;
}
.ofx-pre-seed-sale-content18 {
  height: 400px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.ofx-pre-seed-sale-image16 {
  width: 40px;
  margin-top: 0px;
  object-fit: cover;
  margin-bottom: var(--dl-space-space-unit);
}
.ofx-pre-seed-sale-header22 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  flex-direction: column;
}
.ofx-pre-seed-sale-heading15 {
  color: rgb(255, 255, 255);
}
.ofx-pre-seed-sale-caption15 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  max-width: 600px;
  line-height: 24px;
}
.ofx-pre-seed-sale-link20 {
  display: contents;
}
.ofx-pre-seed-sale-more3 {
  gap: 4px;
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.ofx-pre-seed-sale-more3:hover {
  opacity: 0.5;
}
.ofx-pre-seed-sale-caption16 {
  color: rgb(255, 255, 255);
  max-width: 600px;
  font-style: normal;
  font-weight: 500;
}
.ofx-pre-seed-sale-icon27 {
  fill: #ffffff;
  width: 14px;
  height: 14px;
}
.ofx-pre-seed-sale-slide3 {
  background-color: #00AE45;
}
.ofx-pre-seed-sale-image17 {
  height: 400px;
  object-fit: cover;
  aspect-ratio: 1;
}
.ofx-pre-seed-sale-content19 {
  height: 400px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: space-between;
}
.ofx-pre-seed-sale-image18 {
  width: 40px;
  margin-top: 0px;
  object-fit: cover;
  margin-bottom: var(--dl-space-space-unit);
}
.ofx-pre-seed-sale-header23 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.ofx-pre-seed-sale-heading16 {
  color: rgb(255, 255, 255);
}
.ofx-pre-seed-sale-caption17 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  max-width: 600px;
  line-height: 24px;
}
.ofx-pre-seed-sale-link21 {
  display: contents;
}
.ofx-pre-seed-sale-more4 {
  gap: 4px;
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.ofx-pre-seed-sale-more4:hover {
  opacity: 0.5;
}
.ofx-pre-seed-sale-caption18 {
  color: rgb(255, 255, 255);
  max-width: 600px;
  font-style: normal;
  font-weight: 500;
}
.ofx-pre-seed-sale-icon29 {
  fill: #ffffff;
  width: 14px;
  height: 14px;
}
.ofx-pre-seed-sale-slide4 {
  background-color: #D45230;
}
.ofx-pre-seed-sale-image19 {
  height: 400px;
  object-fit: cover;
  aspect-ratio: 1;
}
.ofx-pre-seed-sale-content20 {
  height: 400px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.ofx-pre-seed-sale-image20 {
  width: 40px;
  margin-top: 0px;
  object-fit: cover;
  margin-bottom: var(--dl-space-space-unit);
}
.ofx-pre-seed-sale-header24 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  flex-direction: column;
}
.ofx-pre-seed-sale-heading17 {
  color: rgb(255, 255, 255);
}
.ofx-pre-seed-sale-caption19 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  max-width: 600px;
  line-height: 24px;
}
.ofx-pre-seed-sale-link22 {
  display: contents;
}
.ofx-pre-seed-sale-more5 {
  gap: 4px;
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.ofx-pre-seed-sale-more5:hover {
  opacity: 0.5;
}
.ofx-pre-seed-sale-caption20 {
  color: rgb(255, 255, 255);
  max-width: 600px;
  font-style: normal;
  font-weight: 500;
}
.ofx-pre-seed-sale-icon31 {
  fill: #ffffff;
  width: 14px;
  height: 14px;
}
.ofx-pre-seed-sale-controls2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.ofx-pre-seed-sale-previous2 {
  display: flex;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  background-color: #2684FC;
}
.ofx-pre-seed-sale-icon33 {
  fill: #ffffff;
  width: 24px;
  height: 24px;
}
.ofx-pre-seed-sale-next2 {
  display: flex;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  background-color: #2684FC;
}
.ofx-pre-seed-sale-icon35 {
  fill: #ffffff;
  width: 24px;
  height: 24px;
}
.ofx-pre-seed-sale-container25 {
  display: contents;
}
.ofx-pre-seed-sale-slider2 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-sixunits);
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: flex-start;
}
.ofx-pre-seed-sale-header25 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.ofx-pre-seed-sale-heading18 {
  fill: #191919;
  color: rgb(25, 25, 25);
  font-size: 60px;
  max-width: 900px;
  font-style: normal;
  font-weight: 400;
}
.ofx-pre-seed-sale-text138 {
  font-style: italic;
  font-family: "Playfair Display";
  font-weight: 500;
}
.ofx-pre-seed-sale-selector {
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: space-between;
}
.ofx-pre-seed-sale-caption21 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
  font-size: 24px;
  max-width: 600px;
  font-weight: 300;
  line-height: 36px;
}
.ofx-pre-seed-sale-text139 {
  font-style: normal;
}
.ofx-pre-seed-sale-text142 {
  color: #ffffff;
  font-style: italic;
  background-color: rgb(38, 132, 252);
}
.ofx-pre-seed-sale-text143 {
  font-style: normal;
}
.ofx-pre-seed-sale-text146 {
  font-style: normal;
}
.ofx-pre-seed-sale-text147 {
  color: #ffffff;
  font-style: italic;
  background-color: rgb(212, 82, 48);
}
.ofx-pre-seed-sale-text148 {
  color: #ffffff;
  font-style: italic;
  background-color: rgb(212, 82, 48);
}
.ofx-pre-seed-sale-text151 {
  color: #ffffff;
  font-style: italic;
  background-color: rgb(222, 173, 28);
}
.ofx-pre-seed-sale-text152 {
  font-style: normal;
}
.ofx-pre-seed-sale-text153 {
  font-style: normal;
}
.ofx-pre-seed-sale-text156 {
  font-style: normal;
}
.ofx-pre-seed-sale-text157 {
  color: #ffffff;
  font-style: italic;
  background-color: rgb(0, 174, 69);
}
.ofx-pre-seed-sale-text158 {
  font-style: normal;
}
.ofx-pre-seed-sale-text159 {
  font-style: normal;
}
.ofx-pre-seed-sale-text162 {
  font-style: normal;
}
.ofx-pre-seed-sale-text163 {
  font-style: normal;
}
.ofx-pre-seed-sale-text166 {
  font-style: italic;
}
.ofx-pre-seed-sale-text168 {
  font-style: normal;
}
.ofx-pre-seed-sale-link23 {
  display: contents;
}
.ofx-pre-seed-sale-more6 {
  gap: 4px;
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.ofx-pre-seed-sale-more6:hover {
  opacity: 0.5;
}
.ofx-pre-seed-sale-caption22 {
  fill: var(--dl-color-primary-300);
  color: var(--dl-color-primary-300);
  max-width: 600px;
  font-style: normal;
  font-weight: 500;
}
.ofx-pre-seed-sale-icon37 {
  fill: var(--dl-color-primary-300);
  width: 14px;
  height: 14px;
}
.ofx-pre-seed-sale-statistics {
  width: 100%;
  display: none;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
}
.ofx-pre-seed-sale-content21 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  border-color: #313133;
  flex-direction: row;
  justify-content: flex-start;
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.ofx-pre-seed-sale-stat1 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  align-items: center;
  border-color: #313133;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
  border-right-width: 1px;
}
.ofx-pre-seed-sale-header26 {
  fill: #191919;
  color: #191919;
  font-size: 62px;
  font-style: italic;
  text-align: center;
  font-family: Playfair Display;
  font-weight: 500;
  line-height: 73px;
}
.ofx-pre-seed-sale-caption23 {
  color: rgb(124, 124, 128);
  font-size: 16px;
  line-height: 24px;
}
.ofx-pre-seed-sale-stat2 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  align-items: center;
  border-color: #313133;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
  border-right-width: 1px;
}
.ofx-pre-seed-sale-header27 {
  fill: #191919;
  color: #191919;
  font-size: 62px;
  font-style: italic;
  text-align: center;
  font-family: Playfair Display;
  font-weight: 500;
  line-height: 73px;
}
.ofx-pre-seed-sale-caption24 {
  color: rgb(124, 124, 128);
  font-size: 16px;
  line-height: 24px;
}
.ofx-pre-seed-sale-stat3 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
}
.ofx-pre-seed-sale-header28 {
  fill: #191919;
  color: #191919;
  font-size: 62px;
  font-style: italic;
  text-align: center;
  font-family: Playfair Display;
  font-weight: 500;
  line-height: 73px;
}
.ofx-pre-seed-sale-caption25 {
  color: rgb(124, 124, 128);
  font-size: 16px;
  line-height: 24px;
}
.ofx-pre-seed-sale-objectives {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
  justify-content: space-between;
}
.ofx-pre-seed-sale-content22 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  border-color: #313133;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.ofx-pre-seed-sale-text170 {
  color: rgb(124, 124, 128);
  font-size: 14px;
  line-height: 21px;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  letter-spacing: 5%;
  padding-bottom: var(--dl-space-space-halfunit);
  text-transform: uppercase;
}
.ofx-pre-seed-sale-objectives-list {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: row;
  justify-content: flex-start;
}
.ofx-pre-seed-sale-text171 {
  fill: #191919;
  color: rgb(25, 25, 25);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}
.ofx-pre-seed-sale-text172 {
  color: rgb(124, 124, 128);
}
.ofx-pre-seed-sale-text173 {
  fill: #191919;
  color: rgb(25, 25, 25);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}
.ofx-pre-seed-sale-text174 {
  color: rgb(124, 124, 128);
}
.ofx-pre-seed-sale-text175 {
  fill: #191919;
  color: rgb(25, 25, 25);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}
.ofx-pre-seed-sale-text176 {
  color: rgb(124, 124, 128);
}
.ofx-pre-seed-sale-customer2 {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: 0px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
}
.ofx-pre-seed-sale-header29 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-self: center;
  padding-left: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.ofx-pre-seed-sale-heading19 {
  color: rgb(25, 25, 25);
  font-size: 60px;
  max-width: 900px;
  font-style: normal;
  font-weight: 400;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
}
.ofx-pre-seed-sale-text178 {
  font-style: italic;
  font-family: "Playfair Display";
}
.ofx-pre-seed-sale-text180 {
  font-size: 24px;
  font-style: normal;
  margin-top: var(--dl-space-space-oneandhalfunits);
  font-weight: 300;
  line-height: 36px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.ofx-pre-seed-sale-container26 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  display: flex;
  overflow-x: scroll;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.ofx-pre-seed-sale-container27 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.ofx-pre-seed-sale-quote16 {
  gap: var(--dl-space-space-twounits);
  height: 100%;
  display: flex;
  padding: 0px;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 0.2);
  flex-direction: column;
  border-right-width: 1px;
}
.ofx-pre-seed-sale-video17 {
  width: 286px;
  height: 498px;
}
.ofx-pre-seed-sale-author16 {
  gap: 4px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.ofx-pre-seed-sale-name16 {
  color: rgb(39, 39, 39);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 1%;
}
.ofx-pre-seed-sale-location16 {
  fill: #272727;
  color: rgb(39, 39, 39);
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 1%;
}
.ofx-pre-seed-sale-container28 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.ofx-pre-seed-sale-quote17 {
  gap: var(--dl-space-space-twounits);
  height: 100%;
  display: flex;
  padding: 0px;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 0.2);
  flex-direction: column;
  border-right-width: 1px;
}
.ofx-pre-seed-sale-video18 {
  width: 286px;
  height: 498px;
}
.ofx-pre-seed-sale-author17 {
  gap: 4px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.ofx-pre-seed-sale-name17 {
  color: rgb(39, 39, 39);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 1%;
}
.ofx-pre-seed-sale-location17 {
  fill: #272727;
  color: rgb(39, 39, 39);
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 1%;
}
.ofx-pre-seed-sale-container29 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.ofx-pre-seed-sale-quote18 {
  gap: var(--dl-space-space-twounits);
  height: 100%;
  display: flex;
  padding: 0px;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 0.2);
  flex-direction: column;
  border-right-width: 1px;
}
.ofx-pre-seed-sale-video19 {
  width: 286px;
  height: 498px;
}
.ofx-pre-seed-sale-author18 {
  gap: 4px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.ofx-pre-seed-sale-name18 {
  color: rgb(39, 39, 39);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 1%;
}
.ofx-pre-seed-sale-location18 {
  fill: #272727;
  color: rgb(39, 39, 39);
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 1%;
}
.ofx-pre-seed-sale-container30 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.ofx-pre-seed-sale-quote19 {
  gap: var(--dl-space-space-twounits);
  height: 100%;
  display: flex;
  padding: 0px;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 0.2);
  flex-direction: column;
  border-right-width: 1px;
}
.ofx-pre-seed-sale-video20 {
  width: 286px;
  height: 498px;
}
.ofx-pre-seed-sale-author19 {
  gap: 4px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.ofx-pre-seed-sale-name19 {
  color: rgb(39, 39, 39);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 1%;
}
.ofx-pre-seed-sale-location19 {
  fill: #272727;
  color: rgb(39, 39, 39);
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 1%;
}
.ofx-pre-seed-sale-container31 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.ofx-pre-seed-sale-quote20 {
  gap: var(--dl-space-space-twounits);
  height: 100%;
  display: flex;
  padding: 0px;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 0.2);
  flex-direction: column;
  border-right-width: 1px;
}
.ofx-pre-seed-sale-video21 {
  width: 286px;
  height: 498px;
}
.ofx-pre-seed-sale-author20 {
  gap: 4px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.ofx-pre-seed-sale-name20 {
  color: rgb(39, 39, 39);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 1%;
}
.ofx-pre-seed-sale-location20 {
  fill: #272727;
  color: rgb(39, 39, 39);
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 1%;
}
.ofx-pre-seed-sale-container32 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.ofx-pre-seed-sale-quote21 {
  gap: var(--dl-space-space-twounits);
  height: 100%;
  display: flex;
  padding: 0px;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 0.2);
  flex-direction: column;
  border-right-width: 1px;
}
.ofx-pre-seed-sale-video22 {
  width: 286px;
  height: 498px;
}
.ofx-pre-seed-sale-author21 {
  gap: 4px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.ofx-pre-seed-sale-name21 {
  color: rgb(39, 39, 39);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 1%;
}
.ofx-pre-seed-sale-location21 {
  fill: #272727;
  color: rgb(39, 39, 39);
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 1%;
}
.ofx-pre-seed-sale-controls3 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: none;
  max-width: var(--dl-size-size-maxwidth);
  align-self: center;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
}
.ofx-pre-seed-sale-previous3 {
  display: flex;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  background-color: #2684FC;
}
.ofx-pre-seed-sale-icon39 {
  fill: #ffffff;
  width: 24px;
  height: 24px;
}
.ofx-pre-seed-sale-next3 {
  display: flex;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  background-color: #2684FC;
}
.ofx-pre-seed-sale-icon41 {
  fill: #ffffff;
  width: 24px;
  height: 24px;
}
.ofx-pre-seed-sale-button5 {
  border-radius: 10px;
  background-color: #2684FC;
}
.ofx-pre-seed-sale-growth {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
}
.ofx-pre-seed-sale-content23 {
  gap: var(--dl-space-space-threeunits);
  flex: 1;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.ofx-pre-seed-sale-header30 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  border-width: 0px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.ofx-pre-seed-sale-header31 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.ofx-pre-seed-sale-heading20 {
  color: rgb(25, 25, 25);
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
}
.ofx-pre-seed-sale-caption26 {
  color: rgb(124, 124, 128);
  width: 100%;
  font-size: 24px;
  max-width: 560px;
  line-height: 36px;
  padding-bottom: 0px;
}
.ofx-pre-seed-sale-checkmarks1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.ofx-pre-seed-sale-check10 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.ofx-pre-seed-sale-mark10 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: #00AE45;
}
.ofx-pre-seed-sale-icon43 {
  fill: #ffffff;
  width: 12px;
  height: 12px;
}
.ofx-pre-seed-sale-text184 {
  fill: #191919;
  color: rgb(25, 25, 25);
  line-height: 24px;
}
.ofx-pre-seed-sale-check11 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.ofx-pre-seed-sale-mark11 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: #00AE45;
}
.ofx-pre-seed-sale-icon45 {
  fill: #ffffff;
  width: 12px;
  height: 12px;
}
.ofx-pre-seed-sale-text185 {
  fill: #191919;
  color: rgb(25, 25, 25);
  line-height: 24px;
}
.ofx-pre-seed-sale-check12 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.ofx-pre-seed-sale-mark12 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: #00AE45;
}
.ofx-pre-seed-sale-icon47 {
  fill: #ffffff;
  width: 12px;
  height: 12px;
}
.ofx-pre-seed-sale-text186 {
  fill: #191919;
  color: rgb(25, 25, 25);
  line-height: 24px;
}
.ofx-pre-seed-sale-check13 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.ofx-pre-seed-sale-mark13 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: #00AE45;
}
.ofx-pre-seed-sale-icon49 {
  fill: #ffffff;
  width: 12px;
  height: 12px;
}
.ofx-pre-seed-sale-text187 {
  fill: #191919;
  color: rgb(25, 25, 25);
  line-height: 24px;
}
.ofx-pre-seed-sale-check14 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.ofx-pre-seed-sale-mark14 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: #00AE45;
}
.ofx-pre-seed-sale-icon51 {
  fill: #ffffff;
  width: 12px;
  height: 12px;
}
.ofx-pre-seed-sale-text188 {
  fill: #191919;
  color: rgb(25, 25, 25);
  line-height: 24px;
}
.ofx-pre-seed-sale-testimonial1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.ofx-pre-seed-sale-content24 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.ofx-pre-seed-sale-text189 {
  color: rgb(124, 124, 128);
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 5%;
  text-transform: uppercase;
}
.ofx-pre-seed-sale-text190 {
  fill: var(--dl-color-gray-500);
  color: var(--dl-color-gray-500);
  font-style: italic;
  font-family: Playfair Display;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 5%;
}
.ofx-pre-seed-sale-information1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.ofx-pre-seed-sale-author22 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  border-color: #7C7C80;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  justify-content: center;
  border-right-width: 0px;
}
.ofx-pre-seed-sale-icon53 {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.ofx-pre-seed-sale-name22 {
  fill: #191919;
  color: rgb(25, 25, 25);
  font-size: 18px;
  line-height: 27px;
}
.ofx-pre-seed-sale-images1 {
  flex: 1;
  display: flex;
  position: relative;
  align-items: flex-end;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: flex-start;
}
.ofx-pre-seed-sale-square1 {
  top: 64px;
  right: -35px;
  width: 180px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  aspect-ratio: 1;
  flex-direction: row;
  justify-content: flex-start;
  background-color: #00AE45;
}
.ofx-pre-seed-sale-image21 {
  width: 600px;
  z-index: 100;
  margin-top: 35px;
  object-fit: cover;
}
.ofx-pre-seed-sale-experience {
  gap: var(--dl-space-space-fourunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
}
.ofx-pre-seed-sale-images2 {
  flex: 1;
  height: 553px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-end;
}
.ofx-pre-seed-sale-square2 {
  left: -35px;
  width: 180px;
  bottom: -35px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  aspect-ratio: 1;
  flex-direction: row;
  justify-content: flex-start;
  background-color: #2684FC;
}
.ofx-pre-seed-sale-image22 {
  width: 507px;
  z-index: 100;
  object-fit: cover;
}
.ofx-pre-seed-sale-content25 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.ofx-pre-seed-sale-header32 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
  border-bottom-width: 1px;
}
.ofx-pre-seed-sale-header-container3 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.ofx-pre-seed-sale-header33 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.ofx-pre-seed-sale-heading21 {
  fill: #191919;
  color: rgb(25, 25, 25);
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
}
.ofx-pre-seed-sale-caption27 {
  color: rgb(124, 124, 128);
  width: 100%;
  font-size: 24px;
  max-width: 560px;
  line-height: 36px;
}
.ofx-pre-seed-sale-checkmarks2 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.ofx-pre-seed-sale-check15 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.ofx-pre-seed-sale-mark15 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: #2684FC;
}
.ofx-pre-seed-sale-icon54 {
  fill: #ffffff;
  width: 12px;
  height: 12px;
}
.ofx-pre-seed-sale-text197 {
  fill: #191919;
  color: rgb(25, 25, 25);
  line-height: 24px;
}
.ofx-pre-seed-sale-check16 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.ofx-pre-seed-sale-mark16 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: #2684FC;
}
.ofx-pre-seed-sale-icon56 {
  fill: #ffffff;
  width: 12px;
  height: 12px;
}
.ofx-pre-seed-sale-text198 {
  fill: #191919;
  color: rgb(25, 25, 25);
  line-height: 24px;
}
.ofx-pre-seed-sale-check17 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.ofx-pre-seed-sale-mark17 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: #2684FC;
}
.ofx-pre-seed-sale-icon58 {
  fill: #ffffff;
  width: 12px;
  height: 12px;
}
.ofx-pre-seed-sale-text199 {
  fill: #191919;
  color: rgb(25, 25, 25);
  line-height: 24px;
}
.ofx-pre-seed-sale-check18 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.ofx-pre-seed-sale-mark18 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: #2684FC;
}
.ofx-pre-seed-sale-icon60 {
  fill: #ffffff;
  width: 12px;
  height: 12px;
}
.ofx-pre-seed-sale-text200 {
  fill: #191919;
  color: rgb(25, 25, 25);
  line-height: 24px;
}
.ofx-pre-seed-sale-check19 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.ofx-pre-seed-sale-mark19 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: #2684FC;
}
.ofx-pre-seed-sale-icon62 {
  fill: #ffffff;
  width: 12px;
  height: 12px;
}
.ofx-pre-seed-sale-text201 {
  fill: #191919;
  color: rgb(25, 25, 25);
  line-height: 24px;
}
.ofx-pre-seed-sale-testimonial2 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.ofx-pre-seed-sale-content26 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.ofx-pre-seed-sale-text202 {
  color: rgb(124, 124, 128);
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 5%;
  text-transform: uppercase;
}
.ofx-pre-seed-sale-text203 {
  fill: var(--dl-color-gray-500);
  color: var(--dl-color-gray-500);
  font-style: italic;
  font-family: "Playfair Display";
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 5%;
}
.ofx-pre-seed-sale-information2 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.ofx-pre-seed-sale-author23 {
  gap: var(--dl-space-space-unit);
  width: 333px;
  display: flex;
  align-items: center;
  border-color: #7C7C80;
  flex-direction: row;
  justify-content: flex-start;
  border-right-width: 0px;
}
.ofx-pre-seed-sale-icon64 {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.ofx-pre-seed-sale-name23 {
  fill: #191919;
  color: rgb(25, 25, 25);
  font-size: 18px;
  line-height: 27px;
}
.ofx-pre-seed-sale-create1 {
  gap: var(--dl-space-space-fourunits);
  width: 100%;
  display: flex;
  padding: 0px;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-end;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
}
.ofx-pre-seed-sale-content27 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 120px;
  flex-direction: column;
  padding-bottom: 120px;
  justify-content: center;
  background-color: #2684FC;
}
.ofx-pre-seed-sale-header34 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.ofx-pre-seed-sale-heading22 {
  color: rgb(255, 255, 255);
  font-size: 100px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
}
.ofx-pre-seed-sale-text209 {
  color: rgb(255, 255, 255);
  font-size: 36px;
  font-style: normal;
  text-align: center;
  font-weight: 300;
}
.ofx-pre-seed-sale-container33 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.ofx-pre-seed-sale-link24 {
  fill: var(--dl-color-primary-300);
  color: var(--dl-color-primary-300);
  width: 259px;
  border-radius: 10px;
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.ofx-pre-seed-sale-text210 {
  fill: #2684FC;
  color: rgb(38, 132, 252);
  font-size: 24px;
}
.ofx-pre-seed-sale-link25 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 259px;
  border-color: var(--dl-color-gray-white);
  border-width: 1px;
  border-radius: 10px;
  text-decoration: none;
  background-color: #2684FC;
}
.ofx-pre-seed-sale-text213 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 20px;
}
.ofx-pre-seed-sale-text216 {
  fill: var(--dl-color-gray-white);
  color: rgba(255, 255, 255, 0.71);
  margin-top: 0px;
}
.ofx-pre-seed-sale-comparision {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.ofx-pre-seed-sale-header-container4 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.ofx-pre-seed-sale-header35 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.ofx-pre-seed-sale-heading23 {
  fill: #191919;
  color: rgb(25, 25, 25);
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
}
.ofx-pre-seed-sale-caption28 {
  color: rgb(124, 124, 128);
  width: 100%;
  font-size: 24px;
  max-width: 560px;
  text-align: center;
  line-height: 36px;
}
.ofx-pre-seed-sale-table {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.ofx-pre-seed-sale-row10 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.ofx-pre-seed-sale-headers10 {
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.ofx-pre-seed-sale-text219 {
  color: rgba(35, 33, 33, 0);
  width: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #313133;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.ofx-pre-seed-sale-plans10 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.ofx-pre-seed-sale-row11 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: #313133;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
}
.ofx-pre-seed-sale-text220 {
  color: rgb(26, 26, 26);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  text-transform: uppercase;
}
.ofx-pre-seed-sale-text221 {
  fill: #1a1a1a;
  color: rgb(26, 26, 26);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  text-transform: uppercase;
}
.ofx-pre-seed-sale-text222 {
  fill: #1a1a1a;
  color: rgb(26, 26, 26);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  text-transform: uppercase;
}
.ofx-pre-seed-sale-row12 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.ofx-pre-seed-sale-headers11 {
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.ofx-pre-seed-sale-text223 {
  fill: #1a1a1a;
  color: rgb(26, 26, 26);
  width: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #313133;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.ofx-pre-seed-sale-plans11 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.ofx-pre-seed-sale-row13 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: #313133;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
}
.ofx-pre-seed-sale-row14 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.ofx-pre-seed-sale-headers12 {
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.ofx-pre-seed-sale-text224 {
  fill: #1a1a1a;
  color: rgb(26, 26, 26);
  width: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #313133;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.ofx-pre-seed-sale-plans12 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.ofx-pre-seed-sale-row15 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: #313133;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
}
.ofx-pre-seed-sale-row16 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.ofx-pre-seed-sale-headers13 {
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.ofx-pre-seed-sale-text225 {
  fill: #1a1a1a;
  color: rgb(26, 26, 26);
  width: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #313133;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.ofx-pre-seed-sale-plans13 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.ofx-pre-seed-sale-row17 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: #313133;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
}
.ofx-pre-seed-sale-text226 {
  color: rgb(39, 39, 39);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
}
.ofx-pre-seed-sale-text227 {
  color: rgb(39, 39, 39);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
}
.ofx-pre-seed-sale-text228 {
  color: rgb(39, 39, 39);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
}
.ofx-pre-seed-sale-row18 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.ofx-pre-seed-sale-headers14 {
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.ofx-pre-seed-sale-text229 {
  fill: #1a1a1a;
  color: rgb(26, 26, 26);
  width: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #313133;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.ofx-pre-seed-sale-text231 {
  color: var(--dl-color-success-300);
}
.ofx-pre-seed-sale-plans14 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.ofx-pre-seed-sale-row19 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: #313133;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
}
.ofx-pre-seed-sale-text232 {
  color: rgb(39, 39, 39);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
}
.ofx-pre-seed-sale-text233 {
  color: rgb(39, 39, 39);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
}
.ofx-pre-seed-sale-text234 {
  color: rgb(39, 39, 39);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
}
.ofx-pre-seed-sale-row20 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.ofx-pre-seed-sale-headers15 {
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.ofx-pre-seed-sale-text235 {
  fill: #1a1a1a;
  color: rgb(26, 26, 26);
  width: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #313133;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.ofx-pre-seed-sale-text237 {
  color: var(--dl-color-success-300);
}
.ofx-pre-seed-sale-plans15 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.ofx-pre-seed-sale-row21 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: #313133;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
}
.ofx-pre-seed-sale-text238 {
  color: rgb(39, 39, 39);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
}
.ofx-pre-seed-sale-text239 {
  color: rgb(39, 39, 39);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
}
.ofx-pre-seed-sale-text240 {
  color: rgb(39, 39, 39);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
}
.ofx-pre-seed-sale-row22 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.ofx-pre-seed-sale-headers16 {
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.ofx-pre-seed-sale-text241 {
  fill: #1a1a1a;
  color: rgb(26, 26, 26);
  width: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #313133;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.ofx-pre-seed-sale-text243 {
  color: var(--dl-color-success-300);
}
.ofx-pre-seed-sale-plans16 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.ofx-pre-seed-sale-row23 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: #313133;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
}
.ofx-pre-seed-sale-text244 {
  color: rgb(39, 39, 39);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
}
.ofx-pre-seed-sale-text245 {
  color: rgb(39, 39, 39);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
}
.ofx-pre-seed-sale-text246 {
  color: rgb(39, 39, 39);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
}
.ofx-pre-seed-sale-row24 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.ofx-pre-seed-sale-headers17 {
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.ofx-pre-seed-sale-text247 {
  fill: #1a1a1a;
  color: rgb(26, 26, 26);
  width: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #313133;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.ofx-pre-seed-sale-plans17 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.ofx-pre-seed-sale-row25 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: #313133;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
}
.ofx-pre-seed-sale-text248 {
  color: rgb(39, 39, 39);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
}
.ofx-pre-seed-sale-text249 {
  color: rgb(39, 39, 39);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
}
.ofx-pre-seed-sale-text250 {
  color: rgb(39, 39, 39);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
}
.ofx-pre-seed-sale-row26 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.ofx-pre-seed-sale-headers18 {
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.ofx-pre-seed-sale-text251 {
  fill: #1a1a1a;
  color: rgb(26, 26, 26);
  width: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #313133;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.ofx-pre-seed-sale-plans18 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.ofx-pre-seed-sale-row27 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: #313133;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
}
.ofx-pre-seed-sale-row28 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.ofx-pre-seed-sale-headers19 {
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.ofx-pre-seed-sale-text252 {
  fill: #1a1a1a;
  color: rgb(26, 26, 26);
  width: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #313133;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.ofx-pre-seed-sale-plans19 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.ofx-pre-seed-sale-row29 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: #313133;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
}
.ofx-pre-seed-sale-text253 {
  color: rgb(39, 39, 39);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
}
.ofx-pre-seed-sale-text254 {
  color: rgb(39, 39, 39);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
}
.ofx-pre-seed-sale-row30 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.ofx-pre-seed-sale-headers20 {
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.ofx-pre-seed-sale-text255 {
  fill: #1a1a1a;
  color: rgb(26, 26, 26);
  width: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #313133;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.ofx-pre-seed-sale-plans20 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.ofx-pre-seed-sale-row31 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: #313133;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
}
.ofx-pre-seed-sale-text256 {
  color: rgb(39, 39, 39);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
}
.ofx-pre-seed-sale-text257 {
  color: rgb(39, 39, 39);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
}
.ofx-pre-seed-sale-row32 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.ofx-pre-seed-sale-headers21 {
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.ofx-pre-seed-sale-text258 {
  fill: #1a1a1a;
  color: rgb(26, 26, 26);
  width: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #313133;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.ofx-pre-seed-sale-plans21 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.ofx-pre-seed-sale-row33 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: #313133;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
}
.ofx-pre-seed-sale-row34 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.ofx-pre-seed-sale-headers22 {
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.ofx-pre-seed-sale-text259 {
  fill: #1a1a1a;
  color: rgb(26, 26, 26);
  width: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #313133;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.ofx-pre-seed-sale-plans22 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.ofx-pre-seed-sale-row35 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: #313133;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
}
.ofx-pre-seed-sale-row36 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.ofx-pre-seed-sale-headers23 {
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.ofx-pre-seed-sale-text260 {
  fill: #1a1a1a;
  color: rgb(26, 26, 26);
  width: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #313133;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.ofx-pre-seed-sale-plans23 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.ofx-pre-seed-sale-row37 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: #313133;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
}
.ofx-pre-seed-sale-row38 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.ofx-pre-seed-sale-headers24 {
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.ofx-pre-seed-sale-text261 {
  fill: #1a1a1a;
  color: rgb(26, 26, 26);
  width: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #313133;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.ofx-pre-seed-sale-plans24 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.ofx-pre-seed-sale-row39 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: #313133;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
}
.ofx-pre-seed-sale-data {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.ofx-pre-seed-sale-header-container5 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.ofx-pre-seed-sale-header36 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.ofx-pre-seed-sale-row40 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.ofx-pre-seed-sale-heading24 {
  fill: #191919;
  color: rgb(25, 25, 25);
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
}
.ofx-pre-seed-sale-caption29 {
  color: rgb(124, 124, 128);
  width: 100%;
  font-size: 24px;
  max-width: 800px;
  line-height: 36px;
}
.ofx-pre-seed-sale-content28 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.ofx-pre-seed-sale-image23 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.ofx-pre-seed-sale-image24 {
  width: 440px;
  height: 436px;
  object-fit: cover;
}
.ofx-pre-seed-sale-accordion {
  width: 100%;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.ofx-pre-seed-sale-element16 {
  transition: 0.3s;
}

.ofx-pre-seed-sale-header37 {
  fill: #191919;
  color: rgb(25, 25, 25);
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  padding-top: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-unit);
}
.ofx-pre-seed-sale-content30 {
  line-height: 28px;
}
.ofx-pre-seed-sale-header38 {
  fill: #191919;
  color: rgb(25, 25, 25);
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  padding-top: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-unit);
}
.ofx-pre-seed-sale-content32 {
  line-height: 28px;
}
.ofx-pre-seed-sale-header39 {
  fill: #191919;
  color: rgb(25, 25, 25);
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  padding-top: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-unit);
}
.ofx-pre-seed-sale-content34 {
  line-height: 28px;
}
.ofx-pre-seed-sale-header40 {
  fill: #191919;
  color: rgb(25, 25, 25);
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  padding-top: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-unit);
}
.ofx-pre-seed-sale-content36 {
  line-height: 28px;
}
.ofx-pre-seed-sale-container35 {
  display: contents;
}
.ofx-pre-seed-sale-faq2 {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 160px;
}
.ofx-pre-seed-sale-header41 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.ofx-pre-seed-sale-heading25 {
  color: #191919;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
}
.ofx-pre-seed-sale-text268 {
  font-style: italic;
  font-family: "Playfair Display";
  font-weight: 500;
}
.ofx-pre-seed-sale-content37 {
  gap: 120px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.ofx-pre-seed-sale-column3 {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.ofx-pre-seed-sale-element20 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.ofx-pre-seed-sale-header42 {
  color: rgb(25, 25, 25);
}
.ofx-pre-seed-sale-content38 {
  color: rgb(124, 124, 128);
  width: 100%;
  display: flex;
  max-width: 560px;
  line-height: 24px;
}
.ofx-pre-seed-sale-element21 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.ofx-pre-seed-sale-header43 {
  color: rgb(25, 25, 25);
}
.ofx-pre-seed-sale-content39 {
  color: rgb(124, 124, 128);
  width: 100%;
  display: flex;
  max-width: 560px;
  line-height: 24px;
}
.ofx-pre-seed-sale-element22 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.ofx-pre-seed-sale-header44 {
  color: rgb(25, 25, 25);
}
.ofx-pre-seed-sale-content40 {
  color: rgb(124, 124, 128);
  width: 100%;
  display: flex;
  max-width: 560px;
  line-height: 24px;
}
.ofx-pre-seed-sale-column4 {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.ofx-pre-seed-sale-element23 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.ofx-pre-seed-sale-header45 {
  color: rgb(25, 25, 25);
}
.ofx-pre-seed-sale-content41 {
  color: rgb(124, 124, 128);
  width: 100%;
  display: flex;
  max-width: 560px;
  line-height: 24px;
}
.ofx-pre-seed-sale-element24 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.ofx-pre-seed-sale-header46 {
  color: rgb(25, 25, 25);
}
.ofx-pre-seed-sale-content42 {
  color: rgb(124, 124, 128);
  width: 100%;
  display: flex;
  max-width: 560px;
  line-height: 24px;
}
.ofx-pre-seed-sale-element25 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.ofx-pre-seed-sale-header47 {
  color: rgb(25, 25, 25);
}
.ofx-pre-seed-sale-content43 {
  color: rgb(124, 124, 128);
  width: 100%;
  display: flex;
  max-width: 560px;
  line-height: 24px;
}
.ofx-pre-seed-sale-create2 {
  gap: var(--dl-space-space-fourunits);
  width: 100%;
  display: flex;
  padding: 0px;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-end;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
}
.ofx-pre-seed-sale-content44 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 120px;
  flex-direction: column;
  padding-bottom: 120px;
  justify-content: center;
  background-color: #2684FC;
}
.ofx-pre-seed-sale-header48 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.ofx-pre-seed-sale-heading26 {
  color: rgb(255, 255, 255);
  font-size: 100px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
}
.ofx-pre-seed-sale-text272 {
  color: rgb(255, 255, 255);
  font-size: 36px;
  font-style: normal;
  text-align: center;
  font-weight: 300;
}
.ofx-pre-seed-sale-container36 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.ofx-pre-seed-sale-link26 {
  fill: var(--dl-color-primary-300);
  color: var(--dl-color-primary-300);
  width: 259px;
  border-radius: 10px;
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.ofx-pre-seed-sale-text273 {
  fill: #2684FC;
  color: rgb(38, 132, 252);
  font-size: 24px;
}
.ofx-pre-seed-sale-link27 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 259px;
  border-color: var(--dl-color-gray-white);
  border-width: 1px;
  border-radius: 10px;
  text-decoration: none;
  background-color: #2684FC;
}
.ofx-pre-seed-sale-text276 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 20px;
}
.ofx-pre-seed-sale-text279 {
  fill: var(--dl-color-gray-white);
  color: rgba(255, 255, 255, 0.71);
  margin-top: 0px;
}
.ofx-pre-seed-sale-footer {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.ofx-pre-seed-sale-content45 {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  border-color: #313133;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  border-top-width: 0px;
}
.ofx-pre-seed-sale-main {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.ofx-pre-seed-sale-branding2 {
  gap: var(--dl-space-space-twounits);
  width: 400px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.ofx-pre-seed-sale-image25 {
  width: 120px;
  object-fit: cover;
}
.ofx-pre-seed-sale-text280 {
  color: #7C7C80;
  line-height: 24px;
}
.ofx-pre-seed-sale-links {
  gap: 120px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.ofx-pre-seed-sale-column5 {
  gap: var(--dl-space-space-threeunits);
  color: white;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.ofx-pre-seed-sale-header49 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
  font-size: 24px;
  font-style: italic;
  font-family: Playfair Display;
  font-weight: 500;
}
.ofx-pre-seed-sale-list1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.ofx-pre-seed-sale-link28 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.ofx-pre-seed-sale-link28:hover {
  opacity: .5;
}
.ofx-pre-seed-sale-link29 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.ofx-pre-seed-sale-link29:hover {
  opacity: .5;
}
.ofx-pre-seed-sale-link30 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.ofx-pre-seed-sale-link30:hover {
  opacity: .5;
}
.ofx-pre-seed-sale-link31 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.ofx-pre-seed-sale-link31:hover {
  opacity: .5;
}
.ofx-pre-seed-sale-link32 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.ofx-pre-seed-sale-link32:hover {
  opacity: .5;
}
.ofx-pre-seed-sale-link33 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.ofx-pre-seed-sale-link33:hover {
  opacity: .5;
}
.ofx-pre-seed-sale-column6 {
  gap: var(--dl-space-space-threeunits);
  color: white;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.ofx-pre-seed-sale-header50 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
  font-size: 24px;
  font-style: italic;
  font-family: Playfair Display;
  font-weight: 500;
}
.ofx-pre-seed-sale-list2 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.ofx-pre-seed-sale-link34 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.ofx-pre-seed-sale-link34:hover {
  opacity: .5;
}
.ofx-pre-seed-sale-link35 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.ofx-pre-seed-sale-link35:hover {
  opacity: .5;
}
.ofx-pre-seed-sale-link36 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.ofx-pre-seed-sale-link36:hover {
  opacity: .5;
}
.ofx-pre-seed-sale-link37 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.ofx-pre-seed-sale-link37:hover {
  opacity: .5;
}
.ofx-pre-seed-sale-link38 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.ofx-pre-seed-sale-link38:hover {
  opacity: .5;
}
.ofx-pre-seed-sale-link39 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.ofx-pre-seed-sale-link39:hover {
  opacity: .5;
}
.ofx-pre-seed-sale-link40 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.ofx-pre-seed-sale-link40:hover {
  opacity: .5;
}
.ofx-pre-seed-sale-link41 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.ofx-pre-seed-sale-link41:hover {
  opacity: .5;
}
.ofx-pre-seed-sale-bottom {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.ofx-pre-seed-sale-text295 {
  color: rgb(124, 124, 128);
}
.ofx-pre-seed-sale-button6 {
  display: flex;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-fiveunits);
  border-radius: 0px;
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  background-color: #2684FC;
}
.ofx-pre-seed-sale-image26 {
  width: 21px;
  object-fit: cover;
}
.ofx-pre-seed-sale-container38 {
  display: contents;
}
@media(max-width: 1200px) {
  .ofx-pre-seed-sale-register {
    fill: var(--dl-color-gray-black);
    color: var(--dl-color-gray-black);
    font-weight: 700;
    border-radius: 10px;
    background-color: var(--dl-color-gray-white);
  }
  .ofx-pre-seed-sale-heading10 {
    font-size: 120px;
    font-family: Fira Sans;
  }
  .ofx-pre-seed-sale-caption10 {
    color: rgb(255, 255, 255);
    font-size: 42px;
    line-height: 52px;
  }
  .ofx-pre-seed-sale-container14 {
    height: 68px;
    margin-bottom: var(--dl-space-space-fourunits);
    flex-direction: column;
  }
  .ofx-pre-seed-sale-text107 {
    color: rgb(255, 255, 255);
    font-size: 32px;
    align-self: flex-start;
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .ofx-pre-seed-sale-textinput1 {
    width: 90%;
    height: auto;
    padding: var(--dl-space-space-unit);
    font-size: 32px;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-halfunit);
    border-radius: 5px;
    padding-right: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .ofx-pre-seed-sale-text108 {
    color: rgb(255, 255, 255);
    align-self: flex-start;
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .ofx-pre-seed-sale-textinput2 {
    color: #ffffff;
    width: 466px;
    height: 26px;
    padding: var(--dl-space-space-unit);
    font-size: 16px;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-halfunit);
    border-radius: 5px;
    padding-right: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
    background-color: rgba(0, 0, 0, 0.65);
  }
  .ofx-pre-seed-sale-customer1 {
    padding-left: 0px;
  }
  .ofx-pre-seed-sale-heading11 {
    padding-bottom: 0px;
  }
  .ofx-pre-seed-sale-text111 {
    fill: var(--dl-color-gray-500);
    color: var(--dl-color-gray-500);
    font-size: 32px;
    font-style: normal;
    font-weight: 300;
    line-height: 36px;
    margin-bottom: 0px;
  }
  .ofx-pre-seed-sale-header16 {
    color: rgb(25, 25, 25);
  }
  .ofx-pre-seed-sale-get-started {
    padding-top: var(--dl-space-space-sixunits);
  }
  .ofx-pre-seed-sale-container23 {
    gap: var(--dl-space-space-halfunit);
  }
  .ofx-pre-seed-sale-text139 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .ofx-pre-seed-sale-text140 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .ofx-pre-seed-sale-text141 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .ofx-pre-seed-sale-text142 {
    color: var(--dl-color-gray-white);
    font-style: italic;
    font-weight: 400;
    background-color: rgb(38, 132, 252);
  }
  .ofx-pre-seed-sale-text143 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .ofx-pre-seed-sale-text144 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .ofx-pre-seed-sale-text145 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .ofx-pre-seed-sale-text146 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .ofx-pre-seed-sale-text147 {
    color: var(--dl-color-gray-white);
    font-style: italic;
    font-weight: 400;
    background-color: rgb(212, 82, 48);
  }
  .ofx-pre-seed-sale-text148 {
    color: var(--dl-color-gray-white);
    font-style: italic;
    font-weight: 400;
    background-color: rgb(212, 82, 48);
  }
  .ofx-pre-seed-sale-text149 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .ofx-pre-seed-sale-text150 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .ofx-pre-seed-sale-text151 {
    color: var(--dl-color-gray-white);
    font-style: italic;
    font-weight: 400;
    background-color: rgb(222, 173, 28);
  }
  .ofx-pre-seed-sale-text152 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .ofx-pre-seed-sale-text153 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .ofx-pre-seed-sale-text154 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .ofx-pre-seed-sale-text155 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .ofx-pre-seed-sale-text156 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .ofx-pre-seed-sale-text157 {
    color: var(--dl-color-gray-white);
    font-style: italic;
    font-weight: 400;
    background-color: rgb(0, 174, 69);
  }
  .ofx-pre-seed-sale-text158 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .ofx-pre-seed-sale-text159 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .ofx-pre-seed-sale-text160 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .ofx-pre-seed-sale-text161 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .ofx-pre-seed-sale-text162 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .ofx-pre-seed-sale-text163 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .ofx-pre-seed-sale-text164 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .ofx-pre-seed-sale-text165 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .ofx-pre-seed-sale-text166 {
    color: var(--dl-color-gray-black);
    font-size: 36px;
    font-style: italic;
    font-family: Playfair Display;
    font-weight: 300;
    line-height: 42px;
  }
  .ofx-pre-seed-sale-text167 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .ofx-pre-seed-sale-text168 {
    color: var(--dl-color-gray-black);
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
  }
  .ofx-pre-seed-sale-text172 {
    color: rgb(124, 124, 128);
  }
  .ofx-pre-seed-sale-customer2 {
    padding-left: 0px;
  }
  .ofx-pre-seed-sale-heading19 {
    padding-bottom: 0px;
  }
  .ofx-pre-seed-sale-text178 {
    font-style: italic;
    font-family: Playfair Display;
  }
  .ofx-pre-seed-sale-text180 {
    fill: var(--dl-color-gray-500);
    color: var(--dl-color-gray-500);
    font-size: 24px;
    margin-top: var(--dl-space-space-unit);
    line-height: 36px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .ofx-pre-seed-sale-text213 {
    font-size: 18px;
  }
  .ofx-pre-seed-sale-caption28 {
    color: rgb(124, 124, 128);
  }
  .ofx-pre-seed-sale-text232 {
    color: rgb(39, 39, 39);
  }
  .ofx-pre-seed-sale-text238 {
    color: rgb(39, 39, 39);
  }
  .ofx-pre-seed-sale-text244 {
    color: rgb(39, 39, 39);
  }
  .ofx-pre-seed-sale-image24 {
    margin-right: var(--dl-space-space-threeunits);
  }
  .ofx-pre-seed-sale-content34 {
    font-size: 22px;
    line-height: 28px;
  }
  .ofx-pre-seed-sale-text280 {
    color: rgb(124, 124, 128);
    line-height: 24px;
  }
  .ofx-pre-seed-sale-link29 {
    color: var(--dl-color-gray-black);
  }
  .ofx-pre-seed-sale-link30 {
    color: var(--dl-color-gray-black);
    font-size: 16px;
    line-height: 32px;
    text-decoration: none;
  }
  .ofx-pre-seed-sale-link31 {
    color: var(--dl-color-gray-black);
  }
  .ofx-pre-seed-sale-link32 {
    color: var(--dl-color-gray-black);
  }
  .ofx-pre-seed-sale-link34 {
    color: var(--dl-color-gray-black);
    font-size: 16px;
    line-height: 32px;
    text-decoration: none;
  }
  .ofx-pre-seed-sale-link35 {
    color: var(--dl-color-gray-black);
    font-size: 16px;
    line-height: 32px;
    text-decoration: none;
  }
  .ofx-pre-seed-sale-link37 {
    color: var(--dl-color-gray-black);
    font-size: 16px;
    line-height: 32px;
    text-decoration: none;
  }
  .ofx-pre-seed-sale-link39 {
    color: var(--dl-color-gray-black);
    font-size: 16px;
    line-height: 32px;
    text-decoration: none;
  }
  .ofx-pre-seed-sale-link40 {
    color: var(--dl-color-gray-black);
  }
  .ofx-pre-seed-sale-link41 {
    color: var(--dl-color-gray-black);
  }
}
@media(max-width: 991px) {
  .ofx-pre-seed-sale-hero {
    padding-left: var(--dl-space-space-oneandhalfunits);
  }
  .ofx-pre-seed-sale-hero-content {
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .ofx-pre-seed-sale-heading10 {
    font-size: 120px;
  }
  .ofx-pre-seed-sale-container14 {
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .ofx-pre-seed-sale-textinput1 {
    font-size: 28px;
  }
  .ofx-pre-seed-sale-customer1 {
    max-width: 1400px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .ofx-pre-seed-sale-header12 {
    max-width: 100%;
  }
  .ofx-pre-seed-sale-heading11 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .ofx-pre-seed-sale-text111 {
    margin-bottom: 0px;
  }
  .ofx-pre-seed-sale-container16 {
    margin-top: 0px;
  }
  .ofx-pre-seed-sale-quote10 {
    gap: var(--dl-space-space-oneandhalfunits);
    width: 100%;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .ofx-pre-seed-sale-quote11 {
    gap: var(--dl-space-space-oneandhalfunits);
    width: 100%;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .ofx-pre-seed-sale-quote12 {
    gap: var(--dl-space-space-oneandhalfunits);
    width: 100%;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .ofx-pre-seed-sale-quote13 {
    gap: var(--dl-space-space-oneandhalfunits);
    width: 100%;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .ofx-pre-seed-sale-quote14 {
    gap: var(--dl-space-space-oneandhalfunits);
    width: 100%;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .ofx-pre-seed-sale-quote15 {
    gap: var(--dl-space-space-oneandhalfunits);
    width: 100%;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .ofx-pre-seed-sale-controls1 {
    max-width: 100%Width;
    padding-left: 0px;
    padding-right: 0px;
  }
  .ofx-pre-seed-sale-previous1 {
    display: none;
  }
  .ofx-pre-seed-sale-next1 {
    display: none;
  }
  .ofx-pre-seed-sale-faq1 {
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .ofx-pre-seed-sale-content10 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    justify-content: flex-start;
  }
  .ofx-pre-seed-sale-column1 {
    max-width: 100%;
  }
  .ofx-pre-seed-sale-header14 {
    font-size: 24px;
  }
  .ofx-pre-seed-sale-content11 {
    line-height: 21px;
  }
  .ofx-pre-seed-sale-text123 {
    font-size: 14px;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-header15 {
    font-size: 24px;
  }
  .ofx-pre-seed-sale-content12 {
    font-size: 14px;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-header16 {
    font-size: 24px;
  }
  .ofx-pre-seed-sale-content13 {
    font-size: 14px;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-column2 {
    max-width: 100%;
  }
  .ofx-pre-seed-sale-header17 {
    font-size: 24px;
  }
  .ofx-pre-seed-sale-content14 {
    font-size: 14px;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-header18 {
    font-size: 24px;
  }
  .ofx-pre-seed-sale-content15 {
    font-size: 14px;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-header19 {
    font-size: 24px;
  }
  .ofx-pre-seed-sale-content16 {
    font-size: 14px;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-get-started {
    justify-content: center;
  }
  .ofx-pre-seed-sale-header-container2 {
    align-self: center;
    align-items: center;
  }
  .ofx-pre-seed-sale-header20 {
    align-items: center;
  }
  .ofx-pre-seed-sale-heading13 {
    color: rgb(25, 25, 25);
    font-size: 60px;
    font-style: normal;
    font-weight: 400;
  }
  .ofx-pre-seed-sale-caption12 {
    color: rgb(124, 124, 128);
    font-size: 24px;
    align-self: center;
    text-align: center;
    line-height: 36px;
  }
  .ofx-pre-seed-sale-container23 {
    gap: var(--dl-space-space-halfunit);
  }
  .ofx-pre-seed-sale-button4 {
    fill: #2684FC;
    color: #2684FC;
    border-color: #2684FC;
    border-width: 1px;
    background-color: #ffffff;
  }
  .ofx-pre-seed-sale-image12 {
    align-self: center;
  }
  .ofx-pre-seed-sale-slider1 {
    gap: var(--dl-space-space-twounits);
  }
  .ofx-pre-seed-sale-image13 {
    width: 100%;
    height: 120px;
  }
  .ofx-pre-seed-sale-content17 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    justify-content: flex-start;
  }
  .ofx-pre-seed-sale-header21 {
    gap: var(--dl-space-space-halfunit);
    width: 100%;
  }
  .ofx-pre-seed-sale-heading14 {
    font-size: 24px;
  }
  .ofx-pre-seed-sale-caption13 {
    max-width: 600px;
  }
  .ofx-pre-seed-sale-image15 {
    width: 100%;
    height: 120px;
  }
  .ofx-pre-seed-sale-content18 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    justify-content: flex-start;
  }
  .ofx-pre-seed-sale-header22 {
    gap: var(--dl-space-space-halfunit);
    width: 100%;
  }
  .ofx-pre-seed-sale-heading15 {
    font-size: 24px;
  }
  .ofx-pre-seed-sale-caption15 {
    max-width: 600px;
  }
  .ofx-pre-seed-sale-image17 {
    width: 100%;
    height: 120px;
  }
  .ofx-pre-seed-sale-content19 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    justify-content: flex-start;
  }
  .ofx-pre-seed-sale-header23 {
    gap: var(--dl-space-space-halfunit);
    width: 100%;
  }
  .ofx-pre-seed-sale-heading16 {
    font-size: 24px;
  }
  .ofx-pre-seed-sale-caption17 {
    fill: var(--dl-color-gray-white);
    color: var(--dl-color-gray-white);
    max-width: 600px;
  }
  .ofx-pre-seed-sale-image19 {
    width: 100%;
    height: 120px;
  }
  .ofx-pre-seed-sale-content20 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    justify-content: flex-start;
  }
  .ofx-pre-seed-sale-header24 {
    gap: var(--dl-space-space-halfunit);
    width: 100%;
  }
  .ofx-pre-seed-sale-heading17 {
    font-size: 24px;
  }
  .ofx-pre-seed-sale-caption19 {
    fill: var(--dl-color-gray-white);
    color: var(--dl-color-gray-white);
    max-width: 600px;
  }
  .ofx-pre-seed-sale-selector {
    gap: var(--dl-space-space-twounits);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  .ofx-pre-seed-sale-content21 {
    padding-top: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .ofx-pre-seed-sale-stat1 {
    width: 100%;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .ofx-pre-seed-sale-stat2 {
    width: 100%;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .ofx-pre-seed-sale-stat3 {
    width: 100%;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .ofx-pre-seed-sale-content22 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    justify-content: flex-start;
  }
  .ofx-pre-seed-sale-objectives-list {
    padding-right: 0px;
  }
  .ofx-pre-seed-sale-objective2 {
    flex: 1;
  }
  .ofx-pre-seed-sale-objective3 {
    flex: 1;
  }
  .ofx-pre-seed-sale-customer2 {
    max-width: 1400px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .ofx-pre-seed-sale-header29 {
    max-width: 100%;
  }
  .ofx-pre-seed-sale-heading19 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .ofx-pre-seed-sale-quote16 {
    gap: var(--dl-space-space-oneandhalfunits);
    width: 100%;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .ofx-pre-seed-sale-quote17 {
    gap: var(--dl-space-space-oneandhalfunits);
    width: 100%;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .ofx-pre-seed-sale-quote18 {
    gap: var(--dl-space-space-oneandhalfunits);
    width: 100%;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .ofx-pre-seed-sale-quote19 {
    gap: var(--dl-space-space-oneandhalfunits);
    width: 100%;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .ofx-pre-seed-sale-quote20 {
    gap: var(--dl-space-space-oneandhalfunits);
    width: 100%;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .ofx-pre-seed-sale-quote21 {
    gap: var(--dl-space-space-oneandhalfunits);
    width: 100%;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .ofx-pre-seed-sale-controls3 {
    max-width: 100%Width;
    padding-left: 0px;
    padding-right: 0px;
  }
  .ofx-pre-seed-sale-previous3 {
    display: none;
  }
  .ofx-pre-seed-sale-next3 {
    display: none;
  }
  .ofx-pre-seed-sale-growth {
    flex-direction: column;
  }
  .ofx-pre-seed-sale-testimonial1 {
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .ofx-pre-seed-sale-text190 {
    color: var(--dl-color-gray-500);
    max-width: 560px;
  }
  .ofx-pre-seed-sale-images1 {
    padding-top: 0px;
  }
  .ofx-pre-seed-sale-square1 {
    top: -20px;
    right: -20px;
  }
  .ofx-pre-seed-sale-image21 {
    margin-top: 0px;
  }
  .ofx-pre-seed-sale-experience {
    align-items: flex-start;
    flex-direction: column-reverse;
  }
  .ofx-pre-seed-sale-text203 {
    color: var(--dl-color-gray-500);
  }
  .ofx-pre-seed-sale-create1 {
    padding: 0px;
  }
  .ofx-pre-seed-sale-content27 {
    gap: var(--dl-space-space-oneandhalfunits);
    padding-top: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .ofx-pre-seed-sale-heading22 {
    font-size: 50px;
  }
  .ofx-pre-seed-sale-link24 {
    width: 219px;
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .ofx-pre-seed-sale-text210 {
    font-size: 16px;
  }
  .ofx-pre-seed-sale-link25 {
    width: 219px;
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .ofx-pre-seed-sale-text213 {
    font-size: 16px;
  }
  .ofx-pre-seed-sale-headers10 {
    flex: 0;
  }
  .ofx-pre-seed-sale-text219 {
    color: rgba(255, 255, 255, 0);
    width: 0px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
  }
  .ofx-pre-seed-sale-row11 {
    padding-right: 0px;
    justify-content: center;
  }
  .ofx-pre-seed-sale-row12 {
    flex-direction: column;
  }
  .ofx-pre-seed-sale-headers11 {
    flex: 0 0 auto;
    width: 100%;
  }
  .ofx-pre-seed-sale-text223 {
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
    border-bottom-width: 0px;
  }
  .ofx-pre-seed-sale-plans11 {
    width: 100%;
  }
  .ofx-pre-seed-sale-row13 {
    padding-right: 0px;
  }
  .ofx-pre-seed-sale-row14 {
    flex-direction: column;
  }
  .ofx-pre-seed-sale-headers12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .ofx-pre-seed-sale-text224 {
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
    border-bottom-width: 0px;
  }
  .ofx-pre-seed-sale-plans12 {
    width: 100%;
  }
  .ofx-pre-seed-sale-row15 {
    padding-right: 0px;
  }
  .ofx-pre-seed-sale-row16 {
    flex-direction: column;
  }
  .ofx-pre-seed-sale-headers13 {
    flex: 0 0 auto;
    width: 100%;
  }
  .ofx-pre-seed-sale-text225 {
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
    border-bottom-width: 0px;
  }
  .ofx-pre-seed-sale-plans13 {
    width: 100%;
  }
  .ofx-pre-seed-sale-row17 {
    padding-right: 0px;
  }
  .ofx-pre-seed-sale-row18 {
    flex-direction: column;
  }
  .ofx-pre-seed-sale-headers14 {
    flex: 0 0 auto;
    width: 100%;
  }
  .ofx-pre-seed-sale-text229 {
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
    border-bottom-width: 0px;
  }
  .ofx-pre-seed-sale-plans14 {
    width: 100%;
  }
  .ofx-pre-seed-sale-row19 {
    padding-right: 0px;
  }
  .ofx-pre-seed-sale-row20 {
    flex-direction: column;
  }
  .ofx-pre-seed-sale-headers15 {
    flex: 0 0 auto;
    width: 100%;
  }
  .ofx-pre-seed-sale-text235 {
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
    border-bottom-width: 0px;
  }
  .ofx-pre-seed-sale-plans15 {
    width: 100%;
  }
  .ofx-pre-seed-sale-row21 {
    padding-right: 0px;
  }
  .ofx-pre-seed-sale-row22 {
    flex-direction: column;
  }
  .ofx-pre-seed-sale-headers16 {
    flex: 0 0 auto;
    width: 100%;
  }
  .ofx-pre-seed-sale-text241 {
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
    border-bottom-width: 0px;
  }
  .ofx-pre-seed-sale-plans16 {
    width: 100%;
  }
  .ofx-pre-seed-sale-row23 {
    padding-right: 0px;
  }
  .ofx-pre-seed-sale-row24 {
    flex-direction: column;
  }
  .ofx-pre-seed-sale-headers17 {
    flex: 0 0 auto;
    width: 100%;
  }
  .ofx-pre-seed-sale-text247 {
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
    border-bottom-width: 0px;
  }
  .ofx-pre-seed-sale-plans17 {
    width: 100%;
  }
  .ofx-pre-seed-sale-row25 {
    padding-right: 0px;
  }
  .ofx-pre-seed-sale-row26 {
    flex-direction: column;
  }
  .ofx-pre-seed-sale-headers18 {
    flex: 0 0 auto;
    width: 100%;
  }
  .ofx-pre-seed-sale-text251 {
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
    border-bottom-width: 0px;
  }
  .ofx-pre-seed-sale-plans18 {
    width: 100%;
  }
  .ofx-pre-seed-sale-row27 {
    padding-right: 0px;
  }
  .ofx-pre-seed-sale-row28 {
    flex-direction: column;
  }
  .ofx-pre-seed-sale-headers19 {
    flex: 0 0 auto;
    width: 100%;
  }
  .ofx-pre-seed-sale-text252 {
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
    border-bottom-width: 0px;
  }
  .ofx-pre-seed-sale-plans19 {
    width: 100%;
  }
  .ofx-pre-seed-sale-row29 {
    padding-right: 0px;
  }
  .ofx-pre-seed-sale-row30 {
    flex-direction: column;
  }
  .ofx-pre-seed-sale-headers20 {
    flex: 0 0 auto;
    width: 100%;
  }
  .ofx-pre-seed-sale-text255 {
    width: 100%;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
    border-bottom-width: 0px;
  }
  .ofx-pre-seed-sale-plans20 {
    width: 100%;
  }
  .ofx-pre-seed-sale-row31 {
    padding-right: 0px;
  }
  .ofx-pre-seed-sale-row32 {
    flex-direction: column;
  }
  .ofx-pre-seed-sale-headers21 {
    flex: 0 0 auto;
    width: 100%;
  }
  .ofx-pre-seed-sale-text258 {
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
    border-bottom-width: 0px;
  }
  .ofx-pre-seed-sale-plans21 {
    width: 100%;
  }
  .ofx-pre-seed-sale-row33 {
    padding-right: 0px;
  }
  .ofx-pre-seed-sale-row34 {
    flex-direction: column;
  }
  .ofx-pre-seed-sale-headers22 {
    flex: 0 0 auto;
    width: 100%;
  }
  .ofx-pre-seed-sale-text259 {
    width: 100%;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
    border-bottom-width: 0px;
  }
  .ofx-pre-seed-sale-plans22 {
    width: 100%;
  }
  .ofx-pre-seed-sale-row35 {
    padding-right: 0px;
  }
  .ofx-pre-seed-sale-row36 {
    flex-direction: column;
  }
  .ofx-pre-seed-sale-headers23 {
    flex: 0 0 auto;
    width: 100%;
  }
  .ofx-pre-seed-sale-text260 {
    width: 100%;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
    border-bottom-width: 0px;
  }
  .ofx-pre-seed-sale-plans23 {
    width: 100%;
  }
  .ofx-pre-seed-sale-row37 {
    padding-right: 0px;
  }
  .ofx-pre-seed-sale-row38 {
    flex-direction: column;
  }
  .ofx-pre-seed-sale-headers24 {
    flex: 0 0 auto;
    width: 100%;
  }
  .ofx-pre-seed-sale-text261 {
    width: 100%;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
    border-bottom-width: 0px;
  }
  .ofx-pre-seed-sale-plans24 {
    width: 100%;
  }
  .ofx-pre-seed-sale-row39 {
    padding-right: 0px;
  }
  .ofx-pre-seed-sale-heading24 {
    color: var(--dl-color-gray-black);
  }
  .ofx-pre-seed-sale-content28 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column-reverse;
    justify-content: flex-start;
  }
  .ofx-pre-seed-sale-image24 {
    margin-right: 0px;
    padding-right: 0px;
  }
  .ofx-pre-seed-sale-header37 {
    font-size: 24px;
  }
  .ofx-pre-seed-sale-header38 {
    font-size: 24px;
  }
  .ofx-pre-seed-sale-header39 {
    font-size: 24px;
  }
  .ofx-pre-seed-sale-header40 {
    font-size: 24px;
  }
  .ofx-pre-seed-sale-faq2 {
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .ofx-pre-seed-sale-content37 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    justify-content: flex-start;
  }
  .ofx-pre-seed-sale-column3 {
    max-width: 100%;
  }
  .ofx-pre-seed-sale-header42 {
    font-size: 24px;
  }
  .ofx-pre-seed-sale-content38 {
    font-size: 14px;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-header43 {
    font-size: 24px;
  }
  .ofx-pre-seed-sale-content39 {
    font-size: 14px;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-header44 {
    font-size: 24px;
  }
  .ofx-pre-seed-sale-content40 {
    font-size: 14px;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-column4 {
    max-width: 100%;
  }
  .ofx-pre-seed-sale-header45 {
    font-size: 24px;
  }
  .ofx-pre-seed-sale-content41 {
    font-size: 14px;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-header46 {
    font-size: 24px;
  }
  .ofx-pre-seed-sale-content42 {
    font-size: 14px;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-header47 {
    font-size: 24px;
  }
  .ofx-pre-seed-sale-content43 {
    font-size: 14px;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-create2 {
    padding: 0px;
  }
  .ofx-pre-seed-sale-content44 {
    gap: var(--dl-space-space-oneandhalfunits);
    padding-top: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .ofx-pre-seed-sale-heading26 {
    font-size: 50px;
  }
  .ofx-pre-seed-sale-link26 {
    width: 219px;
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .ofx-pre-seed-sale-text273 {
    font-size: 16px;
  }
  .ofx-pre-seed-sale-link27 {
    width: 219px;
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .ofx-pre-seed-sale-text276 {
    font-size: 16px;
  }
  .ofx-pre-seed-sale-content45 {
    border-top-width: 0px;
  }
  .ofx-pre-seed-sale-main {
    gap: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .ofx-pre-seed-sale-branding2 {
    width: 100%;
  }
}
@media(max-width: 767px) {
  .ofx-pre-seed-sale-burger-menu {
    display: flex;
  }
  .ofx-pre-seed-sale-hamburger {
    background-color: rgba(255, 255, 255, 0.3);
  }
  .ofx-pre-seed-sale-image11 {
    width: 200px;
  }
  .ofx-pre-seed-sale-heading10 {
    font-size: 120px;
  }
  .ofx-pre-seed-sale-caption10 {
    font-size: 42px;
  }
  .ofx-pre-seed-sale-text103 {
    font-size: 42px;
  }
  .ofx-pre-seed-sale-container13 {
    width: auto;
    height: 194px;
    align-self: stretch;
    align-items: stretch;
    justify-content: space-between;
  }
  .ofx-pre-seed-sale-text107 {
    color: rgb(255, 255, 255);
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
  }
  .ofx-pre-seed-sale-textinput1 {
    width: 681px;
    font-size: 22px;
  }
  .ofx-pre-seed-sale-customer1 {
    gap: var(--dl-space-space-threeunits);
    padding-top: var(--dl-space-space-threeunits);
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .ofx-pre-seed-sale-header12 {
    padding-left: var(--dl-space-space-oneandhalfunits);
  }
  .ofx-pre-seed-sale-text111 {
    fill: var(--dl-color-gray-black);
    color: var(--dl-color-gray-black);
    margin-bottom: 0px;
  }
  .ofx-pre-seed-sale-caption11 {
    font-size: 18px;
  }
  .ofx-pre-seed-sale-icon19 {
    width: 16px;
    height: 26px;
  }
  .ofx-pre-seed-sale-quote10 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-unit);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .ofx-pre-seed-sale-author10 {
    width: 100%;
  }
  .ofx-pre-seed-sale-name10 {
    width: 90%;
    font-size: 22px;
    line-height: autopx;
  }
  .ofx-pre-seed-sale-location10 {
    width: 90%;
    font-size: 18px;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-quote11 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-unit);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .ofx-pre-seed-sale-author11 {
    width: 100%;
  }
  .ofx-pre-seed-sale-name11 {
    width: 90%;
    font-size: 22px;
    line-height: autopx;
  }
  .ofx-pre-seed-sale-location11 {
    width: 90%;
    font-size: 18px;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-quote12 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-unit);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .ofx-pre-seed-sale-author12 {
    width: 100%;
  }
  .ofx-pre-seed-sale-name12 {
    width: 90%;
    font-size: 22px;
    line-height: autopx;
  }
  .ofx-pre-seed-sale-location12 {
    width: 90%;
    font-size: 18px;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-quote13 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-unit);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .ofx-pre-seed-sale-author13 {
    width: 100%;
  }
  .ofx-pre-seed-sale-name13 {
    width: 90%;
    font-size: 22px;
    line-height: autopx;
  }
  .ofx-pre-seed-sale-location13 {
    width: 90%;
    font-size: 18px;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-quote14 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-unit);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .ofx-pre-seed-sale-author14 {
    width: 100%;
  }
  .ofx-pre-seed-sale-name14 {
    width: 90%;
    font-size: 22px;
    line-height: autopx;
  }
  .ofx-pre-seed-sale-location14 {
    width: 90%;
    font-size: 18px;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-quote15 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-unit);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .ofx-pre-seed-sale-author15 {
    width: 100%;
  }
  .ofx-pre-seed-sale-name15 {
    width: 90%;
    font-size: 22px;
    line-height: autopx;
  }
  .ofx-pre-seed-sale-location15 {
    width: 90%;
    font-size: 18px;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-icon21 {
    width: 14px;
    height: 14px;
  }
  .ofx-pre-seed-sale-icon23 {
    width: 14px;
    height: 14px;
  }
  .ofx-pre-seed-sale-faq1 {
    gap: var(--dl-space-space-threeunits);
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .ofx-pre-seed-sale-header13 {
    gap: var(--dl-space-space-unit);
    align-items: center;
  }
  .ofx-pre-seed-sale-heading12 {
    font-size: 36px;
  }
  .ofx-pre-seed-sale-column1 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .ofx-pre-seed-sale-element10 {
    gap: var(--dl-space-space-halfunit);
    padding-top: 0px;
    padding-right: 00px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
  }
  .ofx-pre-seed-sale-header14 {
    font-size: 16px;
    line-height: 20px;
  }
  .ofx-pre-seed-sale-content11 {
    color: rgb(124, 124, 128);
    font-size: 14px;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-text123 {
    font-size: 14px;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-text127 {
    color: rgb(47, 47, 47);
    font-size: 14px;
  }
  .ofx-pre-seed-sale-element11 {
    gap: var(--dl-space-space-halfunit);
    padding-top: 0px;
    padding-right: 00px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
  }
  .ofx-pre-seed-sale-header15 {
    font-size: 16px;
    line-height: 20px;
  }
  .ofx-pre-seed-sale-content12 {
    color: rgb(124, 124, 128);
    font-size: 14px;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-element12 {
    gap: var(--dl-space-space-halfunit);
    padding-top: 0px;
    padding-right: 00px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
  }
  .ofx-pre-seed-sale-header16 {
    font-size: 16px;
    line-height: 20px;
  }
  .ofx-pre-seed-sale-content13 {
    color: rgb(124, 124, 128);
    font-size: 14px;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-column2 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .ofx-pre-seed-sale-element13 {
    gap: var(--dl-space-space-halfunit);
    padding-top: 0px;
    padding-right: 00px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
  }
  .ofx-pre-seed-sale-header17 {
    color: rgb(25, 25, 25);
    font-size: 16px;
    line-height: autopx;
  }
  .ofx-pre-seed-sale-content14 {
    color: rgb(124, 124, 128);
    font-size: 14px;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-element14 {
    gap: var(--dl-space-space-halfunit);
    padding-top: 0px;
    padding-right: 00px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
  }
  .ofx-pre-seed-sale-header18 {
    font-size: 16px;
    line-height: 20px;
  }
  .ofx-pre-seed-sale-content15 {
    color: rgb(124, 124, 128);
    font-size: 14px;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-element15 {
    gap: var(--dl-space-space-halfunit);
    padding-top: 0px;
    padding-right: 00px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
  }
  .ofx-pre-seed-sale-header19 {
    font-size: 16px;
    line-height: 20px;
  }
  .ofx-pre-seed-sale-content16 {
    color: rgb(124, 124, 128);
    font-size: 14px;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-get-started {
    padding: var(--dl-space-space-fourunits);
  }
  .ofx-pre-seed-sale-heading13 {
    font-size: 36px;
    line-height: 42px;
  }
  .ofx-pre-seed-sale-caption12 {
    font-size: 16px;
    line-height: 24px;
  }
  .ofx-pre-seed-sale-container23 {
    gap: var(--dl-space-space-halfunit);
    flex-direction: column;
  }
  .ofx-pre-seed-sale-slides {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .ofx-pre-seed-sale-slide-titles {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .ofx-pre-seed-sale-slider1 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .ofx-pre-seed-sale-slider-track {
    align-items: stretch;
  }
  .ofx-pre-seed-sale-heading14 {
    font-size: 18px;
  }
  .ofx-pre-seed-sale-caption13 {
    font-size: 12px;
    line-height: 18px;
  }
  .ofx-pre-seed-sale-caption14 {
    font-size: 14px;
  }
  .ofx-pre-seed-sale-text135 {
    font-size: 14px;
  }
  .ofx-pre-seed-sale-icon25 {
    width: 11px;
    height: 11px;
  }
  .ofx-pre-seed-sale-heading15 {
    font-size: 18px;
  }
  .ofx-pre-seed-sale-caption15 {
    font-size: 12px;
    line-height: 18px;
  }
  .ofx-pre-seed-sale-caption16 {
    font-size: 14px;
  }
  .ofx-pre-seed-sale-icon27 {
    width: 11px;
    height: 11px;
  }
  .ofx-pre-seed-sale-heading16 {
    font-size: 18px;
  }
  .ofx-pre-seed-sale-caption17 {
    font-size: 12px;
    line-height: 18px;
  }
  .ofx-pre-seed-sale-caption18 {
    font-size: 14px;
  }
  .ofx-pre-seed-sale-icon29 {
    width: 11px;
    height: 11px;
  }
  .ofx-pre-seed-sale-heading17 {
    font-size: 18px;
  }
  .ofx-pre-seed-sale-caption19 {
    font-size: 12px;
    line-height: 18px;
  }
  .ofx-pre-seed-sale-caption20 {
    font-size: 14px;
  }
  .ofx-pre-seed-sale-icon31 {
    width: 11px;
    height: 11px;
  }
  .ofx-pre-seed-sale-previous2 {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .ofx-pre-seed-sale-icon33 {
    width: 14px;
    height: 14px;
  }
  .ofx-pre-seed-sale-next2 {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .ofx-pre-seed-sale-icon35 {
    width: 14px;
    height: 14px;
  }
  .ofx-pre-seed-sale-slider2 {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .ofx-pre-seed-sale-heading18 {
    font-size: 40px;
  }
  .ofx-pre-seed-sale-caption21 {
    font-size: 16px;
    line-height: 24px;
  }
  .ofx-pre-seed-sale-text139 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .ofx-pre-seed-sale-text140 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .ofx-pre-seed-sale-text141 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .ofx-pre-seed-sale-text142 {
    color: var(--dl-color-gray-white);
    font-style: italic;
    font-weight: 400;
    background-color: rgb(38, 132, 252);
  }
  .ofx-pre-seed-sale-text143 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .ofx-pre-seed-sale-text144 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .ofx-pre-seed-sale-text145 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .ofx-pre-seed-sale-text146 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .ofx-pre-seed-sale-text147 {
    color: var(--dl-color-gray-white);
    font-style: italic;
    font-weight: 400;
    background-color: rgb(212, 82, 48);
  }
  .ofx-pre-seed-sale-text148 {
    color: var(--dl-color-gray-white);
    font-style: italic;
    font-weight: 400;
    background-color: rgb(212, 82, 48);
  }
  .ofx-pre-seed-sale-text149 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .ofx-pre-seed-sale-text150 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .ofx-pre-seed-sale-text151 {
    color: var(--dl-color-gray-white);
    font-style: italic;
    font-weight: 400;
    background-color: rgb(222, 173, 28);
  }
  .ofx-pre-seed-sale-text152 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .ofx-pre-seed-sale-text153 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .ofx-pre-seed-sale-text154 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .ofx-pre-seed-sale-text155 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .ofx-pre-seed-sale-text156 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .ofx-pre-seed-sale-text157 {
    color: var(--dl-color-gray-white);
    font-style: italic;
    font-weight: 400;
    background-color: rgb(0, 174, 69);
  }
  .ofx-pre-seed-sale-text158 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .ofx-pre-seed-sale-text159 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .ofx-pre-seed-sale-text160 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .ofx-pre-seed-sale-text161 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .ofx-pre-seed-sale-text162 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .ofx-pre-seed-sale-text163 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .ofx-pre-seed-sale-text164 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .ofx-pre-seed-sale-text165 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .ofx-pre-seed-sale-text166 {
    color: var(--dl-color-gray-black);
    font-size: 36px;
    font-style: italic;
    font-family: Playfair Display;
    font-weight: 300;
    line-height: 42px;
  }
  .ofx-pre-seed-sale-text167 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .ofx-pre-seed-sale-text168 {
    color: var(--dl-color-gray-black);
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
  }
  .ofx-pre-seed-sale-caption22 {
    font-size: 14px;
  }
  .ofx-pre-seed-sale-icon37 {
    width: 11px;
    height: 11px;
  }
  .ofx-pre-seed-sale-statistics {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .ofx-pre-seed-sale-stat1 {
    gap: var(--dl-space-space-unit);
  }
  .ofx-pre-seed-sale-header26 {
    font-size: 36px;
    line-height: 42px;
  }
  .ofx-pre-seed-sale-caption23 {
    font-size: 14px;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-stat2 {
    gap: var(--dl-space-space-unit);
  }
  .ofx-pre-seed-sale-header27 {
    font-size: 36px;
    line-height: 42px;
  }
  .ofx-pre-seed-sale-caption24 {
    font-size: 14px;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-stat3 {
    gap: var(--dl-space-space-unit);
  }
  .ofx-pre-seed-sale-header28 {
    font-size: 36px;
    line-height: 42px;
  }
  .ofx-pre-seed-sale-caption25 {
    font-size: 14px;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-objectives {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .ofx-pre-seed-sale-content22 {
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .ofx-pre-seed-sale-objectives-list {
    width: 100%;
    flex-direction: column;
  }
  .ofx-pre-seed-sale-customer2 {
    gap: var(--dl-space-space-threeunits);
    padding-top: var(--dl-space-space-threeunits);
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .ofx-pre-seed-sale-header29 {
    padding-left: var(--dl-space-space-oneandhalfunits);
  }
  .ofx-pre-seed-sale-text180 {
    fill: var(--dl-color-gray-black);
    color: var(--dl-color-gray-black);
  }
  .ofx-pre-seed-sale-quote16 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-unit);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .ofx-pre-seed-sale-author16 {
    width: 100%;
  }
  .ofx-pre-seed-sale-name16 {
    width: 90%;
    font-size: 14px;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-location16 {
    width: 90%;
    font-size: 14px;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-quote17 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-unit);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .ofx-pre-seed-sale-author17 {
    width: 100%;
  }
  .ofx-pre-seed-sale-name17 {
    width: 90%;
    font-size: 14px;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-location17 {
    width: 90%;
    font-size: 14px;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-quote18 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-unit);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .ofx-pre-seed-sale-author18 {
    width: 100%;
  }
  .ofx-pre-seed-sale-name18 {
    width: 90%;
    font-size: 14px;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-location18 {
    width: 90%;
    font-size: 14px;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-quote19 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-unit);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .ofx-pre-seed-sale-author19 {
    width: 100%;
  }
  .ofx-pre-seed-sale-name19 {
    width: 90%;
    font-size: 14px;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-location19 {
    width: 90%;
    font-size: 14px;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-quote20 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-unit);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .ofx-pre-seed-sale-author20 {
    width: 100%;
  }
  .ofx-pre-seed-sale-name20 {
    width: 90%;
    font-size: 14px;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-location20 {
    width: 90%;
    font-size: 14px;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-quote21 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-unit);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .ofx-pre-seed-sale-author21 {
    width: 100%;
  }
  .ofx-pre-seed-sale-name21 {
    width: 90%;
    font-size: 14px;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-location21 {
    width: 90%;
    font-size: 14px;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-icon39 {
    width: 14px;
    height: 14px;
  }
  .ofx-pre-seed-sale-icon41 {
    width: 14px;
    height: 14px;
  }
  .ofx-pre-seed-sale-growth {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .ofx-pre-seed-sale-content23 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .ofx-pre-seed-sale-header30 {
    gap: var(--dl-space-space-unit);
  }
  .ofx-pre-seed-sale-heading20 {
    font-size: 40px;
  }
  .ofx-pre-seed-sale-caption26 {
    font-size: 16px;
    line-height: 24px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .ofx-pre-seed-sale-text189 {
    font-size: 12px;
    line-height: 18px;
  }
  .ofx-pre-seed-sale-icon53 {
    width: 40px;
    height: 40px;
  }
  .ofx-pre-seed-sale-name22 {
    font-size: 12px;
    line-height: 18px;
  }
  .ofx-pre-seed-sale-image21 {
    width: 100%;
  }
  .ofx-pre-seed-sale-experience {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .ofx-pre-seed-sale-square2 {
    left: -20px;
    width: 20%;
    bottom: -20px;
  }
  .ofx-pre-seed-sale-image22 {
    width: 100%;
  }
  .ofx-pre-seed-sale-content25 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .ofx-pre-seed-sale-header32 {
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .ofx-pre-seed-sale-header-container3 {
    gap: var(--dl-space-space-unit);
  }
  .ofx-pre-seed-sale-heading21 {
    font-size: 40px;
  }
  .ofx-pre-seed-sale-caption27 {
    font-size: 16px;
    line-height: 24px;
  }
  .ofx-pre-seed-sale-text202 {
    font-size: 12px;
    line-height: 18px;
  }
  .ofx-pre-seed-sale-author23 {
    justify-content: flex-start;
  }
  .ofx-pre-seed-sale-icon64 {
    width: 40px;
    height: 40px;
  }
  .ofx-pre-seed-sale-name23 {
    font-size: 12px;
    line-height: 18px;
  }
  .ofx-pre-seed-sale-create1 {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .ofx-pre-seed-sale-content27 {
    gap: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .ofx-pre-seed-sale-heading22 {
    font-size: 48px;
  }
  .ofx-pre-seed-sale-text209 {
    font-size: 32px;
  }
  .ofx-pre-seed-sale-container33 {
    gap: var(--dl-space-space-halfunit);
  }
  .ofx-pre-seed-sale-text210 {
    font-size: 18px;
  }
  .ofx-pre-seed-sale-text213 {
    font-size: 16px;
  }
  .ofx-pre-seed-sale-comparision {
    gap: var(--dl-space-space-threeunits);
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .ofx-pre-seed-sale-header-container4 {
    gap: var(--dl-space-space-unit);
  }
  .ofx-pre-seed-sale-header35 {
    gap: var(--dl-space-space-unit);
    align-items: center;
  }
  .ofx-pre-seed-sale-heading23 {
    font-size: 40px;
  }
  .ofx-pre-seed-sale-caption28 {
    font-size: 16px;
    line-height: 24px;
  }
  .ofx-pre-seed-sale-text220 {
    font-size: 12px;
    line-height: 14px;
  }
  .ofx-pre-seed-sale-text221 {
    font-size: 12px;
    line-height: 14px;
  }
  .ofx-pre-seed-sale-text222 {
    font-size: 12px;
    line-height: 14px;
  }
  .ofx-pre-seed-sale-text223 {
    font-size: 12px;
    line-height: 18px;
  }
  .ofx-pre-seed-sale-text224 {
    font-size: 12px;
    line-height: 18px;
  }
  .ofx-pre-seed-sale-text225 {
    font-size: 12px;
    line-height: 18px;
  }
  .ofx-pre-seed-sale-text226 {
    line-height: 21px;
  }
  .ofx-pre-seed-sale-text227 {
    line-height: 21px;
  }
  .ofx-pre-seed-sale-text228 {
    line-height: 21px;
  }
  .ofx-pre-seed-sale-text229 {
    font-size: 12px;
    line-height: 18px;
  }
  .ofx-pre-seed-sale-text232 {
    line-height: 21px;
  }
  .ofx-pre-seed-sale-text233 {
    line-height: 21px;
  }
  .ofx-pre-seed-sale-text234 {
    line-height: 21px;
  }
  .ofx-pre-seed-sale-text235 {
    font-size: 12px;
    line-height: 18px;
  }
  .ofx-pre-seed-sale-text238 {
    line-height: 21px;
  }
  .ofx-pre-seed-sale-text239 {
    line-height: 21px;
  }
  .ofx-pre-seed-sale-text240 {
    line-height: 21px;
  }
  .ofx-pre-seed-sale-text241 {
    font-size: 12px;
    line-height: 18px;
  }
  .ofx-pre-seed-sale-text244 {
    line-height: 21px;
  }
  .ofx-pre-seed-sale-text245 {
    line-height: 21px;
  }
  .ofx-pre-seed-sale-text246 {
    line-height: 21px;
  }
  .ofx-pre-seed-sale-text247 {
    font-size: 12px;
    line-height: 18px;
  }
  .ofx-pre-seed-sale-text248 {
    line-height: 21px;
  }
  .ofx-pre-seed-sale-text249 {
    line-height: 21px;
  }
  .ofx-pre-seed-sale-text250 {
    line-height: 21px;
  }
  .ofx-pre-seed-sale-text251 {
    font-size: 12px;
    line-height: 18px;
  }
  .ofx-pre-seed-sale-text252 {
    font-size: 12px;
    line-height: 18px;
  }
  .ofx-pre-seed-sale-text253 {
    line-height: 21px;
  }
  .ofx-pre-seed-sale-text254 {
    line-height: 21px;
  }
  .ofx-pre-seed-sale-text255 {
    font-size: 12px;
    line-height: 18px;
  }
  .ofx-pre-seed-sale-text256 {
    line-height: 21px;
  }
  .ofx-pre-seed-sale-text257 {
    line-height: 21px;
  }
  .ofx-pre-seed-sale-text258 {
    font-size: 12px;
    line-height: 18px;
  }
  .ofx-pre-seed-sale-text259 {
    font-size: 12px;
    line-height: 18px;
  }
  .ofx-pre-seed-sale-text260 {
    font-size: 12px;
    line-height: 18px;
  }
  .ofx-pre-seed-sale-text261 {
    font-size: 12px;
    line-height: 18px;
  }
  .ofx-pre-seed-sale-data {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .ofx-pre-seed-sale-heading24 {
    font-size: 36px;
  }
  .ofx-pre-seed-sale-caption29 {
    font-size: 16px;
    line-height: 24px;
  }
  .ofx-pre-seed-sale-image23 {
    width: 100%;
  }
  .ofx-pre-seed-sale-image24 {
    width: 100%;
    padding-right: 0px;
  }
  .ofx-pre-seed-sale-faq2 {
    gap: var(--dl-space-space-threeunits);
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .ofx-pre-seed-sale-header41 {
    gap: var(--dl-space-space-unit);
    align-items: center;
  }
  .ofx-pre-seed-sale-heading25 {
    font-size: 36px;
  }
  .ofx-pre-seed-sale-column3 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .ofx-pre-seed-sale-element20 {
    gap: var(--dl-space-space-halfunit);
    padding-top: 0px;
    padding-right: 00px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
  }
  .ofx-pre-seed-sale-header42 {
    font-size: 16px;
    line-height: 20px;
  }
  .ofx-pre-seed-sale-element21 {
    gap: var(--dl-space-space-halfunit);
    padding-top: 0px;
    padding-right: 00px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
  }
  .ofx-pre-seed-sale-header43 {
    font-size: 16px;
    line-height: 20px;
  }
  .ofx-pre-seed-sale-element22 {
    gap: var(--dl-space-space-halfunit);
    padding-top: 0px;
    padding-right: 00px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
  }
  .ofx-pre-seed-sale-header44 {
    font-size: 16px;
    line-height: 20px;
  }
  .ofx-pre-seed-sale-column4 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .ofx-pre-seed-sale-element23 {
    gap: var(--dl-space-space-halfunit);
    padding-top: 0px;
    padding-right: 00px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
  }
  .ofx-pre-seed-sale-header45 {
    font-size: 16px;
    line-height: 20px;
  }
  .ofx-pre-seed-sale-element24 {
    gap: var(--dl-space-space-halfunit);
    padding-top: 0px;
    padding-right: 00px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
  }
  .ofx-pre-seed-sale-header46 {
    font-size: 16px;
    line-height: 20px;
  }
  .ofx-pre-seed-sale-element25 {
    gap: var(--dl-space-space-halfunit);
    padding-top: 0px;
    padding-right: 00px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
  }
  .ofx-pre-seed-sale-header47 {
    font-size: 16px;
    line-height: 20px;
  }
  .ofx-pre-seed-sale-create2 {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .ofx-pre-seed-sale-content44 {
    gap: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .ofx-pre-seed-sale-heading26 {
    font-size: 48px;
  }
  .ofx-pre-seed-sale-text272 {
    font-size: 32px;
  }
  .ofx-pre-seed-sale-container36 {
    gap: var(--dl-space-space-halfunit);
  }
  .ofx-pre-seed-sale-text273 {
    font-size: 18px;
  }
  .ofx-pre-seed-sale-link27 {
    fill: var(--dl-color-gray-white);
    color: var(--dl-color-gray-white);
    border-color: var(--dl-color-gray-white);
    border-width: 1px;
    background-color: #2684FC;
  }
  .ofx-pre-seed-sale-text276 {
    fill: var(--dl-color-gray-white);
    color: var(--dl-color-gray-white);
    font-size: 16px;
  }
  .ofx-pre-seed-sale-footer {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .ofx-pre-seed-sale-links {
    gap: var(--dl-space-space-fiveunits);
    flex-direction: row;
  }
}
@media(max-width: 479px) {
  .ofx-pre-seed-sale-branding1 {
    width: 93px;
    padding-top: var(--dl-space-space-unit);
  }
  .ofx-pre-seed-sale-hamburger {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: rgba(255, 255, 255, 0.3);
  }
  .ofx-pre-seed-sale-icon10 {
    width: 30px;
  }
  .ofx-pre-seed-sale-mobile-menu {
    padding: 16px;
  }
  .ofx-pre-seed-sale-hero-content {
    gap: var(--dl-space-space-threeunits);
    height: 712px;
    align-self: center;
    padding-top: var(--dl-space-space-fourunits);
    justify-content: flex-start;
  }
  .ofx-pre-seed-sale-header-container1 {
    align-items: flex-start;
    justify-content: center;
  }
  .ofx-pre-seed-sale-image11 {
    width: 158px;
    height: 41px;
  }
  .ofx-pre-seed-sale-heading10 {
    font-size: 64px;
  }
  .ofx-pre-seed-sale-caption10 {
    color: rgb(255, 255, 255);
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
  }
  .ofx-pre-seed-sale-text103 {
    font-size: 28px;
  }
  .ofx-pre-seed-sale-container13 {
    gap: var(--dl-space-space-unit);
    height: auto;
    margin-top: 0px;
    justify-content: flex-start;
  }
  .ofx-pre-seed-sale-container14 {
    margin-bottom: 0px;
  }
  .ofx-pre-seed-sale-text107 {
    font-size: 22px;
  }
  .ofx-pre-seed-sale-textinput1 {
    width: 100%;
    font-size: 10px;
  }
  .ofx-pre-seed-sale-container15 {
    margin-top: 0px;
  }
  .ofx-pre-seed-sale-text108 {
    color: rgb(255, 255, 255);
    font-size: 14px;
    margin-bottom: 0px;
  }
  .ofx-pre-seed-sale-textinput2 {
    width: 100%;
    height: 25px;
    font-size: 10px;
  }
  .ofx-pre-seed-sale-customer1 {
    position: relative;
  }
  .ofx-pre-seed-sale-heading11 {
    font-size: 48px;
    padding-bottom: 0px;
  }
  .ofx-pre-seed-sale-text111 {
    fill: var(--dl-color-gray-black);
    color: var(--dl-color-gray-black);
    font-size: 28px;
    font-style: normal;
    font-weight: 300;
    line-height: 36px;
    margin-bottom: 0px;
  }
  .ofx-pre-seed-sale-caption11 {
    font-size: 18px;
  }
  .ofx-pre-seed-sale-icon19 {
    width: 17px;
    height: 21px;
  }
  .ofx-pre-seed-sale-container16 {
    gap: var(--dl-space-space-halfunit);
    margin-top: var(--dl-space-space-twounits);
    flex-direction: row;
    justify-content: flex-start;
  }
  .ofx-pre-seed-sale-container17 {
    flex: 1;
    width: auto;
  }
  .ofx-pre-seed-sale-quote10 {
    padding: 0px;
  }
  .ofx-pre-seed-sale-name10 {
    color: #272727;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: autopx;
  }
  .ofx-pre-seed-sale-location10 {
    color: #272727;
    font-size: 18px;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-container18 {
    flex: 1;
    width: auto;
  }
  .ofx-pre-seed-sale-quote11 {
    width: auto;
    height: auto;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .ofx-pre-seed-sale-name11 {
    color: #272727;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: autopx;
  }
  .ofx-pre-seed-sale-location11 {
    color: #272727;
    font-size: 18px;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-container19 {
    flex: 1;
    width: auto;
  }
  .ofx-pre-seed-sale-quote12 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .ofx-pre-seed-sale-name12 {
    color: #272727;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: autopx;
  }
  .ofx-pre-seed-sale-location12 {
    color: #272727;
    font-size: 18px;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-container20 {
    flex: 1;
    width: auto;
  }
  .ofx-pre-seed-sale-quote13 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .ofx-pre-seed-sale-name13 {
    color: #272727;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: autopx;
  }
  .ofx-pre-seed-sale-location13 {
    color: #272727;
    font-size: 18px;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-container21 {
    flex: 1;
    width: auto;
  }
  .ofx-pre-seed-sale-quote14 {
    padding: 0px;
  }
  .ofx-pre-seed-sale-name14 {
    color: #272727;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: autopx;
  }
  .ofx-pre-seed-sale-location14 {
    color: #272727;
    font-size: 18px;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-container22 {
    flex: 1;
    width: auto;
  }
  .ofx-pre-seed-sale-quote15 {
    padding: 0px;
  }
  .ofx-pre-seed-sale-name15 {
    color: #272727;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: autopx;
  }
  .ofx-pre-seed-sale-location15 {
    color: #272727;
    font-size: 18px;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-button1 {
    display: none;
    text-align: center;
  }
  .ofx-pre-seed-sale-header13 {
    gap: 0px;
    align-items: flex-start;
    flex-direction: column;
  }
  .ofx-pre-seed-sale-heading12 {
    color: rgb(25, 25, 25);
    font-size: 36px;
  }
  .ofx-pre-seed-sale-text121 {
    font-style: normal;
  }
  .ofx-pre-seed-sale-text122 {
    font-style: italic;
    font-family: Fira Sans;
    font-weight: 400;
  }
  .ofx-pre-seed-sale-header14 {
    color: rgb(25, 25, 25);
    line-height: 20px;
  }
  .ofx-pre-seed-sale-content11 {
    color: rgb(124, 124, 128);
    font-size: 14px;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-text123 {
    color: rgb(124, 124, 128);
    font-size: 14px;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-header15 {
    color: rgb(25, 25, 25);
    line-height: 20px;
  }
  .ofx-pre-seed-sale-content12 {
    color: rgb(124, 124, 128);
    font-size: 14px;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-header16 {
    color: rgb(25, 25, 25);
    line-height: 20px;
  }
  .ofx-pre-seed-sale-header17 {
    color: rgb(25, 25, 25);
    line-height: 20px;
  }
  .ofx-pre-seed-sale-content14 {
    color: rgb(124, 124, 128);
    font-size: 14px;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-header18 {
    color: rgb(25, 25, 25);
    font-size: 16px;
    line-height: 20px;
  }
  .ofx-pre-seed-sale-header19 {
    color: rgb(25, 25, 25);
    font-size: 16px;
    line-height: 20px;
  }
  .ofx-pre-seed-sale-heading13 {
    align-self: center;
    text-align: center;
  }
  .ofx-pre-seed-sale-caption12 {
    color: rgb(124, 124, 128);
    font-size: 24px;
    align-self: center;
    line-height: 36px;
  }
  .ofx-pre-seed-sale-container23 {
    gap: var(--dl-space-space-halfunit);
    flex-direction: column;
  }
  .ofx-pre-seed-sale-image12 {
    align-self: center;
  }
  .ofx-pre-seed-sale-slide-titles {
    display: none;
  }
  .ofx-pre-seed-sale-slider-container {
    padding-top: 0px;
  }
  .ofx-pre-seed-sale-slide1 {
    align-items: flex-start;
    justify-content: flex-start;
  }
  .ofx-pre-seed-sale-heading14 {
    font-size: 22px;
  }
  .ofx-pre-seed-sale-caption13 {
    color: rgb(255, 255, 255);
    font-size: 22px;
    line-height: 24px;
  }
  .ofx-pre-seed-sale-slide2 {
    align-items: flex-start;
    justify-content: flex-start;
  }
  .ofx-pre-seed-sale-heading15 {
    font-size: 22px;
  }
  .ofx-pre-seed-sale-caption15 {
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
  }
  .ofx-pre-seed-sale-heading16 {
    font-size: 22px;
  }
  .ofx-pre-seed-sale-caption17 {
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
  }
  .ofx-pre-seed-sale-heading17 {
    font-size: 22px;
  }
  .ofx-pre-seed-sale-caption19 {
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
  }
  .ofx-pre-seed-sale-heading18 {
    font-size: 38px;
    line-height: 48px;
  }
  .ofx-pre-seed-sale-caption21 {
    font-size: 16px;
    line-height: 24px;
  }
  .ofx-pre-seed-sale-text139 {
    font-style: normal;
  }
  .ofx-pre-seed-sale-text140 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .ofx-pre-seed-sale-text141 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .ofx-pre-seed-sale-text142 {
    color: var(--dl-color-gray-white);
    font-style: italic;
    font-weight: 400;
    background-color: rgb(38, 132, 252);
  }
  .ofx-pre-seed-sale-text143 {
    font-style: normal;
  }
  .ofx-pre-seed-sale-text144 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .ofx-pre-seed-sale-text145 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .ofx-pre-seed-sale-text146 {
    font-style: normal;
  }
  .ofx-pre-seed-sale-text147 {
    color: var(--dl-color-gray-white);
    font-style: italic;
    font-weight: 400;
    background-color: rgb(212, 82, 48);
  }
  .ofx-pre-seed-sale-text148 {
    color: var(--dl-color-gray-white);
    font-style: italic;
    font-weight: 400;
    background-color: rgb(212, 82, 48);
  }
  .ofx-pre-seed-sale-text149 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .ofx-pre-seed-sale-text150 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .ofx-pre-seed-sale-text151 {
    color: var(--dl-color-gray-white);
    font-style: italic;
    font-weight: 400;
    background-color: rgb(222, 173, 28);
  }
  .ofx-pre-seed-sale-text152 {
    font-style: normal;
  }
  .ofx-pre-seed-sale-text153 {
    font-style: normal;
  }
  .ofx-pre-seed-sale-text154 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .ofx-pre-seed-sale-text155 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .ofx-pre-seed-sale-text156 {
    font-style: normal;
  }
  .ofx-pre-seed-sale-text157 {
    color: var(--dl-color-gray-white);
    font-style: italic;
    font-weight: 400;
    background-color: rgb(0, 174, 69);
  }
  .ofx-pre-seed-sale-text158 {
    font-style: normal;
  }
  .ofx-pre-seed-sale-text159 {
    font-style: normal;
  }
  .ofx-pre-seed-sale-text160 {
    font-style: normal;
  }
  .ofx-pre-seed-sale-text161 {
    font-style: normal;
  }
  .ofx-pre-seed-sale-text162 {
    font-style: normal;
  }
  .ofx-pre-seed-sale-text163 {
    font-style: normal;
  }
  .ofx-pre-seed-sale-text164 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .ofx-pre-seed-sale-text165 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .ofx-pre-seed-sale-text166 {
    font-size: 38px;
    font-style: italic;
    font-family: Playfair Display;
    font-weight: 400;
    line-height: 42px;
  }
  .ofx-pre-seed-sale-text167 {
    font-size: 38px;
    font-style: normal;
    font-family: Playfair Display;
    font-weight: 400;
  }
  .ofx-pre-seed-sale-text168 {
    font-size: 18px;
    font-style: normal;
    line-height: 32px;
  }
  .ofx-pre-seed-sale-header26 {
    color: #191919;
  }
  .ofx-pre-seed-sale-header27 {
    fill: #191919;
    color: #191919;
  }
  .ofx-pre-seed-sale-header28 {
    fill: #191919;
    color: #191919;
  }
  .ofx-pre-seed-sale-content22 {
    border-bottom-width: 1px;
  }
  .ofx-pre-seed-sale-text170 {
    font-size: 12px;
    line-height: 18px;
  }
  .ofx-pre-seed-sale-text171 {
    font-size: 18px;
  }
  .ofx-pre-seed-sale-text173 {
    font-size: 18px;
  }
  .ofx-pre-seed-sale-text175 {
    font-size: 18px;
  }
  .ofx-pre-seed-sale-customer2 {
    position: relative;
  }
  .ofx-pre-seed-sale-heading19 {
    padding-bottom: 0px;
  }
  .ofx-pre-seed-sale-text178 {
    font-style: italic;
    font-family: Playfair Display;
    font-weight: 500;
  }
  .ofx-pre-seed-sale-text180 {
    fill: var(--dl-color-gray-black);
    color: var(--dl-color-gray-black);
    font-size: 28px;
    font-style: normal;
    font-weight: 300;
    line-height: 36px;
  }
  .ofx-pre-seed-sale-container26 {
    gap: var(--dl-space-space-halfunit);
    flex-direction: row;
    justify-content: flex-start;
  }
  .ofx-pre-seed-sale-container27 {
    flex: 1;
    width: auto;
  }
  .ofx-pre-seed-sale-quote16 {
    padding: 0px;
  }
  .ofx-pre-seed-sale-name16 {
    color: #272727;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-location16 {
    color: #272727;
    font-size: 14px;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-container28 {
    flex: 1;
    width: auto;
  }
  .ofx-pre-seed-sale-quote17 {
    width: auto;
    height: auto;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .ofx-pre-seed-sale-name17 {
    color: #272727;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-location17 {
    color: #272727;
    font-size: 14px;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-container29 {
    flex: 1;
    width: auto;
  }
  .ofx-pre-seed-sale-quote18 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .ofx-pre-seed-sale-name18 {
    color: #272727;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-location18 {
    color: #272727;
    font-size: 14px;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-container30 {
    flex: 1;
    width: auto;
  }
  .ofx-pre-seed-sale-quote19 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .ofx-pre-seed-sale-name19 {
    color: #272727;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-location19 {
    color: #272727;
    font-size: 14px;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-container31 {
    flex: 1;
    width: auto;
  }
  .ofx-pre-seed-sale-quote20 {
    padding: 0px;
  }
  .ofx-pre-seed-sale-name20 {
    color: #272727;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-location20 {
    color: #272727;
    font-size: 14px;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-container32 {
    flex: 1;
    width: auto;
  }
  .ofx-pre-seed-sale-quote21 {
    padding: 0px;
  }
  .ofx-pre-seed-sale-name21 {
    color: #272727;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-location21 {
    color: #272727;
    font-size: 14px;
    line-height: 21px;
  }
  .ofx-pre-seed-sale-button5 {
    display: none;
    text-align: center;
  }
  .ofx-pre-seed-sale-heading20 {
    color: rgb(25, 25, 25);
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
  }
  .ofx-pre-seed-sale-caption26 {
    color: rgb(124, 124, 128);
    font-size: 22px;
    line-height: 28px;
  }
  .ofx-pre-seed-sale-text189 {
    color: rgb(124, 124, 128);
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
  }
  .ofx-pre-seed-sale-text190 {
    color: var(--dl-color-gray-500);
  }
  .ofx-pre-seed-sale-heading21 {
    color: rgb(25, 25, 25);
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
  }
  .ofx-pre-seed-sale-caption27 {
    color: rgb(124, 124, 128);
    line-height: 28px;
  }
  .ofx-pre-seed-sale-text202 {
    color: rgb(124, 124, 128);
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
  }
  .ofx-pre-seed-sale-name23 {
    font-size: 12px;
    line-height: 18px;
  }
  .ofx-pre-seed-sale-create1 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .ofx-pre-seed-sale-content27 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .ofx-pre-seed-sale-heading22 {
    color: rgb(255, 255, 255);
    font-style: normal;
    text-align: center;
    font-weight: 500;
  }
  .ofx-pre-seed-sale-text209 {
    font-size: 28px;
  }
  .ofx-pre-seed-sale-container33 {
    gap: var(--dl-space-space-halfunit);
  }
  .ofx-pre-seed-sale-link24 {
    text-align: center;
    border-radius: 10px;
  }
  .ofx-pre-seed-sale-text210 {
    fill: #2684FC;
    color: #2684FC;
  }
  .ofx-pre-seed-sale-link25 {
    text-align: center;
    border-radius: 10px;
  }
  .ofx-pre-seed-sale-text213 {
    fill: var(--dl-color-gray-white);
    color: var(--dl-color-gray-white);
    font-size: 16px;
  }
  .ofx-pre-seed-sale-text216 {
    fill: var(--dl-color-gray-white);
    color: rgba(255, 255, 255, 0.72);
    margin-top: 0px;
  }
  .ofx-pre-seed-sale-header35 {
    gap: 0px;
    flex-direction: column;
  }
  .ofx-pre-seed-sale-heading23 {
    color: #272727;
    text-align: center;
  }
  .ofx-pre-seed-sale-caption28 {
    font-size: 22px;
    line-height: 28px;
  }
  .ofx-pre-seed-sale-data {
    gap: var(--dl-space-space-unit);
  }
  .ofx-pre-seed-sale-heading24 {
    color: #272727;
    font-size: 36px;
  }
  .ofx-pre-seed-sale-caption29 {
    font-size: 22px;
    line-height: 32px;
  }
  .ofx-pre-seed-sale-content28 {
    flex-direction: column-reverse;
  }
  .ofx-pre-seed-sale-image24 {
    padding-right: 0px;
  }
  .ofx-pre-seed-sale-header37 {
    font-size: 20px;
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .ofx-pre-seed-sale-content30 {
    font-size: 16px;
    line-height: 24px;
  }
  .ofx-pre-seed-sale-header38 {
    font-size: 20px;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .ofx-pre-seed-sale-content32 {
    font-size: 16px;
    line-height: 24px;
  }
  .ofx-pre-seed-sale-header39 {
    font-size: 20px;
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .ofx-pre-seed-sale-content34 {
    font-size: 16px;
    line-height: 24px;
  }
  .ofx-pre-seed-sale-header40 {
    font-size: 20px;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .ofx-pre-seed-sale-content36 {
    font-size: 16px;
  }
  .ofx-pre-seed-sale-header41 {
    gap: 0px;
    align-items: flex-start;
    flex-direction: column;
  }
  .ofx-pre-seed-sale-create2 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .ofx-pre-seed-sale-content44 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .ofx-pre-seed-sale-heading26 {
    color: rgb(255, 255, 255);
    font-style: normal;
    text-align: center;
    font-weight: 500;
  }
  .ofx-pre-seed-sale-text272 {
    font-size: 28px;
  }
  .ofx-pre-seed-sale-link26 {
    text-align: center;
    border-radius: 10px;
  }
  .ofx-pre-seed-sale-text273 {
    fill: #2684FC;
    color: #2684FC;
  }
  .ofx-pre-seed-sale-link27 {
    text-align: center;
    border-radius: 10px;
  }
  .ofx-pre-seed-sale-text276 {
    fill: var(--dl-color-gray-white);
    color: var(--dl-color-gray-white);
  }
  .ofx-pre-seed-sale-text279 {
    fill: var(--dl-color-gray-white);
    color: rgba(255, 255, 255, 0.72);
    margin-top: 0px;
  }
  .ofx-pre-seed-sale-links {
    gap: 0;
    width: 432px;
    padding-right: var(--dl-space-space-oneandhalfunits);
    flex-direction: row;
    justify-content: flex-start;
  }
  .ofx-pre-seed-sale-column5 {
    flex: 1;
  }
  .ofx-pre-seed-sale-column6 {
    flex: 1;
    align-items: flex-end;
  }
  .ofx-pre-seed-sale-link39 {
    color: var(--dl-color-gray-black);
    font-size: 16px;
    line-height: 32px;
    text-decoration: none;
  }
  .ofx-pre-seed-sale-link40 {
    color: var(--dl-color-gray-black);
    font-size: 16px;
    line-height: 32px;
    text-decoration: none;
  }
  .ofx-pre-seed-sale-link41 {
    color: var(--dl-color-gray-black);
    font-size: 16px;
    line-height: 32px;
    text-decoration: none;
  }
  .ofx-pre-seed-sale-bottom {
    gap: var(--dl-space-space-twounits);
    flex-direction: column;
    justify-content: center;
  }
  .ofx-pre-seed-sale-button6 {
    justify-content: center;
  }
}
