.line-line {
  width: 32px;
  height: 1px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  background-color: #ffffff;
}
.lineroot-class-name {
  background-color: #272727;
}
.lineroot-class-name1 {
  background-color: #272727;
}
.lineroot-class-name2 {
  background-color: #272727;
}
.lineroot-class-name3 {
  background-color: #272727;
}
.lineroot-class-name4 {
  background-color: #272727;
}
.lineroot-class-name5 {
  background-color: #272727;
}
.lineroot-class-name6 {
  background-color: #272727;
}
.lineroot-class-name7 {
  background-color: #272727;
}
.lineroot-class-name8 {
  background-color: #272727;
}
.lineroot-class-name9 {
  background-color: #272727;
}
.lineroot-class-name10 {
  background-color: #272727;
}
.lineroot-class-name11 {
  background-color: #272727;
}
.lineroot-class-name12 {
  background-color: #272727;
}
.lineroot-class-name13 {
  background-color: #272727;
}
.lineroot-class-name14 {
  background-color: #272727;
}
.lineroot-class-name15 {
  background-color: #272727;
}
.lineroot-class-name16 {
  background-color: #272727;
}
.lineroot-class-name17 {
  background-color: #272727;
}
.lineroot-class-name18 {
  background-color: #272727;
}
.lineroot-class-name19 {
  background-color: #272727;
}
.lineroot-class-name20 {
  background-color: #272727;
}
.lineroot-class-name21 {
  background-color: #272727;
}
.lineroot-class-name22 {
  background-color: #272727;
}
.lineroot-class-name23 {
  background-color: #272727;
}
