.home-container10 {
  width: 100%;
  display: flex;
  overflow: hidden;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
}
.home-hero {
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
  flex-direction: column;
  justify-content: flex-start;
  background-color: #000000;
}
.home-header10 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.home-navbar {
  width: 100%;
  display: flex;
  z-index: 1000;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  justify-content: space-between;
}
.home-link10 {
  display: contents;
}
.home-branding1 {
  width: 120px;
  object-fit: cover;
  text-decoration: none;
}
.home-burger-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-hamburger {
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-fourunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.3);
}
.home-hamburger:hover {
  opacity: 0.5;
}
.home-icon10 {
  width: 60px;
  object-fit: cover;
}
.home-mobile-menu {
  top: 0px;
  left: 0px;
  color: var(--dl-color-gray-white);
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 32px;
  z-index: 100;
  position: fixed;
  transform: translateX(100%);
  transition: 0.5s;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--dl-color-gray-black);
}
.home-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container11 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-image10 {
  height: 2rem;
}
.home-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-icon11 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-nav2 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-navlink {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-link11 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-link12 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-link13 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-link14 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-link15 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-container12 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
}
.home-register {
  text-decoration: none;
}
.home-icon-group {
  fill: var(--dl-color-gray-white);
  display: flex;
}
.home-icon13 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon15 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon17 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-video1 {
  top: 0px;
  left: 0px;
  width: 100%;
  filter: opacity(0.6);
  height: 100%;
  position: absolute;
  object-fit: cover;
}
.home-hero-content {
  gap: var(--dl-space-space-fiveunits);
  flex: 1;
  width: 100%;
  display: flex;
  z-index: 100;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-header-container1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-header11 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-image11 {
  width: 300px;
  object-fit: cover;
  margin-bottom: var(--dl-space-space-unit);
}
.home-heading10 {
  color: rgb(255, 255, 255);
  font-size: 150px;
  max-width: 790px;
  font-style: normal;
  font-family: "Fira Sans";
  font-weight: 600;
}
.home-caption10 {
  color: rgb(255, 255, 255);
  font-size: 42px;
  max-width: 600px;
  line-height: 52px;
}
.home-link16 {
  color: #ffffff;
  width: 353px;
  align-self: flex-start;
  text-align: center;
  border-color: rgba(255, 255, 255, 0.72);
  border-width: 1px;
  border-radius: 10px;
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.15);
}
.home-text103 {
  font-size: 28px;
  text-align: center;
}
.home-text106 {
  color: rgba(255, 255, 255, 0.69);
  font-size: 20px;
  margin-top: -50px;
}
.home-get-started {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-sixunits);
  padding-right: var(--dl-space-space-sixunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
}
.home-header-container2 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  z-index: 100;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-header12 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 800px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-heading11 {
  fill: #191919;
  color: rgb(25, 25, 25);
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
}
.home-caption11 {
  color: rgb(124, 124, 128);
  font-size: 24px;
  max-width: 600px;
  text-align: center;
  line-height: 36px;
}
.home-button1 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-container13 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-button2 {
  width: 293px;
  text-align: center;
  border-radius: 10px;
  text-decoration: none;
  background-color: #2684fc;
}
.home-text107 {
  text-align: center;
}
.home-button3 {
  fill: #2684fc;
  color: #2684fc;
  width: 293px;
  text-align: center;
  border-color: #2684fc;
  border-width: 1px;
  border-radius: 10px;
  text-decoration: none;
  background-color: #ffffff;
}
.home-text109 {
  fill: var(--dl-color-gray-500);
  color: var(--dl-color-gray-500);
  margin-top: var(--dl-space-space-oneandhalfunits);
}
.home-image12 {
  width: 200px;
  object-fit: cover;
}
.home-slides {
  gap: var(--dl-space-space-fourunits);
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-direction: column;
  padding-bottom: 0px;
}
.home-slide-titles {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-slide-title1 {
  flex: 1;
  border-color: #fdc827;
}
.home-text110 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
}
.home-slide-title2 {
  flex: 1;
  border-color: var(--dl-color-primary-300);
}
.home-text111 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
}
.home-slide-title3 {
  flex: 1;
  border-color: #00ae45;
}
.home-text112 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
}
.home-slide-title4 {
  flex: 1;
  border-color: #d45230;
}
.home-text113 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
}
.home-slider1 {
  gap: var(--dl-space-space-fiveunits);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-slider-container {
  gap: var(--dl-space-space-fourunits);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-slider-track-container {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-slider-track {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-slide1 {
  background-color: #dead1c;
}
.home-image13 {
  height: 400px;
  object-fit: cover;
  aspect-ratio: 1;
}
.home-content10 {
  height: 400px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-image14 {
  width: 40px;
  margin-top: 0px;
  object-fit: cover;
  margin-bottom: var(--dl-space-space-unit);
}
.home-header13 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  flex-direction: column;
}
.home-heading12 {
  color: #ffffff;
}
.home-caption12 {
  fill: var(--dl-color-gray-black);
  color: rgb(255, 255, 255);
  max-width: 600px;
  line-height: 24px;
}
.home-link17 {
  display: contents;
}
.home-more1 {
  gap: 4px;
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.home-more1:hover {
  opacity: 0.5;
}
.home-caption13 {
  color: rgb(255, 255, 255);
  max-width: 600px;
  font-style: normal;
  font-weight: 500;
}
.home-icon19 {
  fill: #ffffff;
  width: 14px;
  height: 14px;
}
.home-slide2 {
  background-color: #2684fc;
}
.home-image15 {
  width: 343px;
  height: 400px;
  object-fit: cover;
  aspect-ratio: 1;
}
.home-content11 {
  height: 400px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-image16 {
  width: 40px;
  margin-top: 0px;
  object-fit: cover;
  margin-bottom: var(--dl-space-space-unit);
}
.home-header14 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  flex-direction: column;
}
.home-heading13 {
  color: rgb(255, 255, 255);
}
.home-caption14 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  max-width: 600px;
  line-height: 24px;
}
.home-link18 {
  display: contents;
}
.home-more2 {
  gap: 4px;
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.home-more2:hover {
  opacity: 0.5;
}
.home-caption15 {
  color: rgb(255, 255, 255);
  max-width: 600px;
  font-style: normal;
  font-weight: 500;
}
.home-icon21 {
  fill: #ffffff;
  width: 14px;
  height: 14px;
}
.home-slide3 {
  background-color: #00ae45;
}
.home-image17 {
  height: 400px;
  object-fit: cover;
  aspect-ratio: 1;
}
.home-content12 {
  height: 400px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: space-between;
}
.home-image18 {
  width: 40px;
  margin-top: 0px;
  object-fit: cover;
  margin-bottom: var(--dl-space-space-unit);
}
.home-header15 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.home-heading14 {
  color: rgb(255, 255, 255);
}
.home-caption16 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  max-width: 600px;
  line-height: 24px;
}
.home-link19 {
  display: contents;
}
.home-more3 {
  gap: 4px;
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.home-more3:hover {
  opacity: 0.5;
}
.home-caption17 {
  color: rgb(255, 255, 255);
  max-width: 600px;
  font-style: normal;
  font-weight: 500;
}
.home-icon23 {
  fill: #ffffff;
  width: 14px;
  height: 14px;
}
.home-slide4 {
  background-color: #d45230;
}
.home-image19 {
  height: 400px;
  object-fit: cover;
  aspect-ratio: 1;
}
.home-content13 {
  height: 400px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-image20 {
  width: 40px;
  margin-top: 0px;
  object-fit: cover;
  margin-bottom: var(--dl-space-space-unit);
}
.home-header16 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  flex-direction: column;
}
.home-heading15 {
  color: rgb(255, 255, 255);
}
.home-caption18 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  max-width: 600px;
  line-height: 24px;
}
.home-link20 {
  display: contents;
}
.home-more4 {
  gap: 4px;
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.home-more4:hover {
  opacity: 0.5;
}
.home-caption19 {
  color: rgb(255, 255, 255);
  max-width: 600px;
  font-style: normal;
  font-weight: 500;
}
.home-icon25 {
  fill: #ffffff;
  width: 14px;
  height: 14px;
}
.home-controls1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-previous1 {
  display: flex;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  background-color: #2684fc;
}
.home-icon27 {
  fill: #ffffff;
  width: 24px;
  height: 24px;
}
.home-next1 {
  display: flex;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  background-color: #2684fc;
}
.home-icon29 {
  fill: #ffffff;
  width: 24px;
  height: 24px;
}
.home-container15 {
  display: contents;
}
.home-slider2 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-sixunits);
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: flex-start;
}
.home-header17 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-heading16 {
  fill: #191919;
  color: rgb(25, 25, 25);
  font-size: 60px;
  max-width: 900px;
  font-style: normal;
  font-weight: 400;
}
.home-text117 {
  font-style: italic;
  font-family: "Playfair Display";
  font-weight: 500;
}
.home-selector {
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: space-between;
}
.home-caption20 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
  font-size: 24px;
  max-width: 600px;
  font-weight: 300;
  line-height: 36px;
}
.home-text118 {
  font-style: normal;
}
.home-text121 {
  color: #ffffff;
  font-style: italic;
  background-color: rgb(38, 132, 252);
}
.home-text122 {
  font-style: normal;
}
.home-text125 {
  font-style: normal;
}
.home-text126 {
  color: #ffffff;
  font-style: italic;
  background-color: rgb(212, 82, 48);
}
.home-text127 {
  color: #ffffff;
  font-style: italic;
  background-color: rgb(212, 82, 48);
}
.home-text130 {
  color: #ffffff;
  font-style: italic;
  background-color: rgb(222, 173, 28);
}
.home-text131 {
  font-style: normal;
}
.home-text132 {
  font-style: normal;
}
.home-text135 {
  font-style: normal;
}
.home-text136 {
  color: #ffffff;
  font-style: italic;
  background-color: rgb(0, 174, 69);
}
.home-text137 {
  font-style: normal;
}
.home-text138 {
  font-style: normal;
}
.home-text141 {
  font-style: normal;
}
.home-text142 {
  font-style: normal;
}
.home-text145 {
  font-style: italic;
}
.home-text147 {
  font-style: normal;
}
.home-link21 {
  display: contents;
}
.home-more5 {
  gap: 4px;
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
}
.home-more5:hover {
  opacity: 0.5;
}
.home-caption21 {
  fill: var(--dl-color-primary-300);
  color: var(--dl-color-primary-300);
  max-width: 600px;
  font-style: normal;
  font-weight: 500;
}
.home-icon31 {
  fill: var(--dl-color-primary-300);
  width: 14px;
  height: 14px;
}
.home-statistics {
  width: 100%;
  display: none;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
}
.home-content14 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  border-color: #313133;
  flex-direction: row;
  justify-content: flex-start;
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.home-stat1 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  align-items: center;
  border-color: #313133;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
  border-right-width: 1px;
}
.home-header18 {
  fill: #191919;
  color: #191919;
  font-size: 62px;
  font-style: italic;
  text-align: center;
  font-family: Playfair Display;
  font-weight: 500;
  line-height: 73px;
}
.home-caption22 {
  color: rgb(124, 124, 128);
  font-size: 16px;
  line-height: 24px;
}
.home-stat2 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  align-items: center;
  border-color: #313133;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
  border-right-width: 1px;
}
.home-header19 {
  fill: #191919;
  color: #191919;
  font-size: 62px;
  font-style: italic;
  text-align: center;
  font-family: Playfair Display;
  font-weight: 500;
  line-height: 73px;
}
.home-caption23 {
  color: rgb(124, 124, 128);
  font-size: 16px;
  line-height: 24px;
}
.home-stat3 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
}
.home-header20 {
  fill: #191919;
  color: #191919;
  font-size: 62px;
  font-style: italic;
  text-align: center;
  font-family: Playfair Display;
  font-weight: 500;
  line-height: 73px;
}
.home-caption24 {
  color: rgb(124, 124, 128);
  font-size: 16px;
  line-height: 24px;
}
.home-objectives {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
  justify-content: space-between;
}
.home-content15 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  border-color: #313133;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.home-text149 {
  color: rgb(124, 124, 128);
  font-size: 14px;
  line-height: 21px;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  letter-spacing: 5%;
  padding-bottom: var(--dl-space-space-halfunit);
  text-transform: uppercase;
}
.home-objectives-list {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: row;
  justify-content: flex-start;
}
.home-text150 {
  fill: #191919;
  color: rgb(25, 25, 25);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}
.home-text151 {
  color: rgb(124, 124, 128);
}
.home-text152 {
  fill: #191919;
  color: rgb(25, 25, 25);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}
.home-text153 {
  color: rgb(124, 124, 128);
}
.home-text154 {
  fill: #191919;
  color: rgb(25, 25, 25);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}
.home-text155 {
  color: rgb(124, 124, 128);
}
.home-customer {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: 0px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
}
.home-header21 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-self: center;
  padding-left: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.home-heading17 {
  color: rgb(25, 25, 25);
  font-size: 60px;
  max-width: 900px;
  font-style: normal;
  font-weight: 400;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
}
.home-text157 {
  font-style: italic;
  font-family: "Playfair Display";
}
.home-text159 {
  font-size: 24px;
  font-style: normal;
  margin-top: var(--dl-space-space-oneandhalfunits);
  font-weight: 300;
  line-height: 36px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-container16 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  display: flex;
  overflow-x: scroll;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.home-container17 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-quote1 {
  gap: var(--dl-space-space-twounits);
  height: 100%;
  display: flex;
  padding: 0px;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 0.2);
  flex-direction: column;
  border-right-width: 1px;
}
.home-video2 {
  width: 286px;
  height: 498px;
}
.home-author1 {
  gap: 4px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-name1 {
  color: rgb(39, 39, 39);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 1%;
}
.home-location1 {
  fill: #272727;
  color: rgb(39, 39, 39);
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 1%;
}
.home-container18 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-quote2 {
  gap: var(--dl-space-space-twounits);
  height: 100%;
  display: flex;
  padding: 0px;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 0.2);
  flex-direction: column;
  border-right-width: 1px;
}
.home-video3 {
  width: 286px;
  height: 498px;
}
.home-author2 {
  gap: 4px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-name2 {
  color: rgb(39, 39, 39);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 1%;
}
.home-location2 {
  fill: #272727;
  color: rgb(39, 39, 39);
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 1%;
}
.home-container19 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-quote3 {
  gap: var(--dl-space-space-twounits);
  height: 100%;
  display: flex;
  padding: 0px;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 0.2);
  flex-direction: column;
  border-right-width: 1px;
}
.home-video4 {
  width: 286px;
  height: 498px;
}
.home-author3 {
  gap: 4px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-name3 {
  color: rgb(39, 39, 39);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 1%;
}
.home-location3 {
  fill: #272727;
  color: rgb(39, 39, 39);
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 1%;
}
.home-container20 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-quote4 {
  gap: var(--dl-space-space-twounits);
  height: 100%;
  display: flex;
  padding: 0px;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 0.2);
  flex-direction: column;
  border-right-width: 1px;
}
.home-video5 {
  width: 286px;
  height: 498px;
}
.home-author4 {
  gap: 4px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-name4 {
  color: rgb(39, 39, 39);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 1%;
}
.home-location4 {
  fill: #272727;
  color: rgb(39, 39, 39);
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 1%;
}
.home-container21 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-quote5 {
  gap: var(--dl-space-space-twounits);
  height: 100%;
  display: flex;
  padding: 0px;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 0.2);
  flex-direction: column;
  border-right-width: 1px;
}
.home-video6 {
  width: 286px;
  height: 498px;
}
.home-author5 {
  gap: 4px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-name5 {
  color: rgb(39, 39, 39);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 1%;
}
.home-location5 {
  fill: #272727;
  color: rgb(39, 39, 39);
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 1%;
}
.home-container22 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-quote6 {
  gap: var(--dl-space-space-twounits);
  height: 100%;
  display: flex;
  padding: 0px;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 0.2);
  flex-direction: column;
  border-right-width: 1px;
}
.home-video7 {
  width: 286px;
  height: 498px;
}
.home-author6 {
  gap: 4px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-name6 {
  color: rgb(39, 39, 39);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 1%;
}
.home-location6 {
  fill: #272727;
  color: rgb(39, 39, 39);
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 1%;
}
.home-controls2 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: none;
  max-width: var(--dl-size-size-maxwidth);
  align-self: center;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
}
.home-previous2 {
  display: flex;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  background-color: #2684fc;
}
.home-icon33 {
  fill: #ffffff;
  width: 24px;
  height: 24px;
}
.home-next2 {
  display: flex;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  background-color: #2684fc;
}
.home-icon35 {
  fill: #ffffff;
  width: 24px;
  height: 24px;
}
.home-button4 {
  border-radius: 10px;
  background-color: #2684fc;
  text-align: center;
}
.home-growth {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
}
.home-content16 {
  gap: var(--dl-space-space-threeunits);
  flex: 1;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-header22 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  border-width: 0px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.home-header23 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-heading18 {
  color: rgb(25, 25, 25);
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
}
.home-caption25 {
  color: rgb(124, 124, 128);
  width: 100%;
  font-size: 24px;
  max-width: 560px;
  line-height: 36px;
  padding-bottom: 0px;
}
.home-checkmarks1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-check10 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-mark10 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: #00ae45;
}
.home-icon37 {
  fill: #ffffff;
  width: 12px;
  height: 12px;
}
.home-text163 {
  fill: #191919;
  color: rgb(25, 25, 25);
  line-height: 24px;
}
.home-check11 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-mark11 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: #00ae45;
}
.home-icon39 {
  fill: #ffffff;
  width: 12px;
  height: 12px;
}
.home-text164 {
  fill: #191919;
  color: rgb(25, 25, 25);
  line-height: 24px;
}
.home-check12 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-mark12 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: #00ae45;
}
.home-icon41 {
  fill: #ffffff;
  width: 12px;
  height: 12px;
}
.home-text165 {
  fill: #191919;
  color: rgb(25, 25, 25);
  line-height: 24px;
}
.home-check13 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-mark13 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: #00ae45;
}
.home-icon43 {
  fill: #ffffff;
  width: 12px;
  height: 12px;
}
.home-text166 {
  fill: #191919;
  color: rgb(25, 25, 25);
  line-height: 24px;
}
.home-check14 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-mark14 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: #00ae45;
}
.home-icon45 {
  fill: #ffffff;
  width: 12px;
  height: 12px;
}
.home-text167 {
  fill: #191919;
  color: rgb(25, 25, 25);
  line-height: 24px;
}
.home-testimonial1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-content17 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-text168 {
  color: rgb(124, 124, 128);
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 5%;
  text-transform: uppercase;
}
.home-text169 {
  fill: var(--dl-color-gray-500);
  color: var(--dl-color-gray-500);
  font-style: italic;
  font-family: Playfair Display;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 5%;
}
.home-information1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-author7 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  border-color: #7c7c80;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  justify-content: center;
  border-right-width: 0px;
}
.home-icon47 {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.home-name7 {
  fill: #191919;
  color: rgb(25, 25, 25);
  font-size: 18px;
  line-height: 27px;
}
.home-images1 {
  flex: 1;
  display: flex;
  position: relative;
  align-items: flex-end;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: flex-start;
}
.home-square1 {
  top: 64px;
  right: -35px;
  width: 180px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  aspect-ratio: 1;
  flex-direction: row;
  justify-content: flex-start;
  background-color: #00ae45;
}
.home-image21 {
  width: 600px;
  z-index: 100;
  margin-top: 35px;
  object-fit: cover;
}
.home-experience {
  gap: var(--dl-space-space-fourunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
}
.home-images2 {
  flex: 1;
  height: 553px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-end;
}
.home-square2 {
  left: -35px;
  width: 180px;
  bottom: -35px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  aspect-ratio: 1;
  flex-direction: row;
  justify-content: flex-start;
  background-color: #2684fc;
}
.home-image22 {
  width: 507px;
  z-index: 100;
  object-fit: cover;
}
.home-content18 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-header24 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
  border-bottom-width: 1px;
}
.home-header-container3 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-header25 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-heading19 {
  fill: #191919;
  color: rgb(25, 25, 25);
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
}
.home-caption26 {
  color: rgb(124, 124, 128);
  width: 100%;
  font-size: 24px;
  max-width: 560px;
  line-height: 36px;
}
.home-checkmarks2 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-check15 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-mark15 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: #2684fc;
}
.home-icon48 {
  fill: #ffffff;
  width: 12px;
  height: 12px;
}
.home-text176 {
  fill: #191919;
  color: rgb(25, 25, 25);
  line-height: 24px;
}
.home-check16 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-mark16 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: #2684fc;
}
.home-icon50 {
  fill: #ffffff;
  width: 12px;
  height: 12px;
}
.home-text177 {
  fill: #191919;
  color: rgb(25, 25, 25);
  line-height: 24px;
}
.home-check17 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-mark17 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: #2684fc;
}
.home-icon52 {
  fill: #ffffff;
  width: 12px;
  height: 12px;
}
.home-text178 {
  fill: #191919;
  color: rgb(25, 25, 25);
  line-height: 24px;
}
.home-check18 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-mark18 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: #2684fc;
}
.home-icon54 {
  fill: #ffffff;
  width: 12px;
  height: 12px;
}
.home-text179 {
  fill: #191919;
  color: rgb(25, 25, 25);
  line-height: 24px;
}
.home-check19 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-mark19 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: #2684fc;
}
.home-icon56 {
  fill: #ffffff;
  width: 12px;
  height: 12px;
}
.home-text180 {
  fill: #191919;
  color: rgb(25, 25, 25);
  line-height: 24px;
}
.home-testimonial2 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-content19 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-text181 {
  color: rgb(124, 124, 128);
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 5%;
  text-transform: uppercase;
}
.home-text182 {
  fill: var(--dl-color-gray-500);
  color: var(--dl-color-gray-500);
  font-style: italic;
  font-family: "Playfair Display";
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 5%;
}
.home-information2 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-author8 {
  gap: var(--dl-space-space-unit);
  width: 333px;
  display: flex;
  align-items: center;
  border-color: #7c7c80;
  flex-direction: row;
  justify-content: flex-start;
  border-right-width: 0px;
}
.home-icon58 {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.home-name8 {
  fill: #191919;
  color: rgb(25, 25, 25);
  font-size: 18px;
  line-height: 27px;
}
.home-create1 {
  gap: var(--dl-space-space-fourunits);
  width: 100%;
  display: flex;
  padding: 0px;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-end;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
}
.home-content20 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 120px;
  flex-direction: column;
  padding-bottom: 120px;
  justify-content: center;
  background-color: #2684fc;
}
.home-header26 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-heading20 {
  color: rgb(255, 255, 255);
  font-size: 100px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
}
.home-text188 {
  color: rgb(255, 255, 255);
  font-size: 36px;
  font-style: normal;
  text-align: center;
  font-weight: 300;
}
.home-container23 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-link22 {
  fill: var(--dl-color-primary-300);
  color: var(--dl-color-primary-300);
  width: 259px;
  border-radius: 10px;
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.home-text189 {
  fill: #2684fc;
  color: rgb(38, 132, 252);
  font-size: 24px;
}
.home-link23 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 259px;
  border-color: var(--dl-color-gray-white);
  border-width: 1px;
  border-radius: 10px;
  text-decoration: none;
  background-color: #2684fc;
  text-align: center;
}
.home-text192 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 20px;
  text-align: center;
}
.home-text195 {
  fill: var(--dl-color-gray-white);
  color: rgba(255, 255, 255, 0.71);
  margin-top: 0px;
}
.home-comparision {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.home-header-container4 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-header27 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-heading21 {
  fill: #191919;
  color: rgb(25, 25, 25);
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
}
.home-caption27 {
  color: rgb(124, 124, 128);
  width: 100%;
  font-size: 24px;
  max-width: 560px;
  text-align: center;
  line-height: 36px;
}
.home-table {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-row10 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-headers10 {
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-text198 {
  color: rgba(35, 33, 33, 0);
  width: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #313133;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.home-plans10 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-row11 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: #313133;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
}
.home-text199 {
  color: rgb(26, 26, 26);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  text-transform: uppercase;
}
.home-text200 {
  fill: #1a1a1a;
  color: rgb(26, 26, 26);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  text-transform: uppercase;
}
.home-text201 {
  fill: #1a1a1a;
  color: rgb(26, 26, 26);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  text-transform: uppercase;
}
.home-row12 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-headers11 {
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-text202 {
  fill: #1a1a1a;
  color: rgb(26, 26, 26);
  width: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #313133;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.home-plans11 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-row13 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: #313133;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
}
.home-row14 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-headers12 {
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-text203 {
  fill: #1a1a1a;
  color: rgb(26, 26, 26);
  width: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #313133;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.home-plans12 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-row15 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: #313133;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
}
.home-row16 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-headers13 {
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-text204 {
  fill: #1a1a1a;
  color: rgb(26, 26, 26);
  width: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #313133;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.home-plans13 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-row17 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: #313133;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
}
.home-text205 {
  color: rgb(39, 39, 39);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
}
.home-text206 {
  color: rgb(39, 39, 39);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
}
.home-text207 {
  color: rgb(39, 39, 39);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
}
.home-row18 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-headers14 {
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-text208 {
  fill: #1a1a1a;
  color: rgb(26, 26, 26);
  width: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #313133;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.home-text210 {
  color: var(--dl-color-success-300);
}
.home-plans14 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-row19 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: #313133;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
}
.home-text211 {
  color: rgb(39, 39, 39);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
}
.home-text212 {
  color: rgb(39, 39, 39);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
}
.home-text213 {
  color: rgb(39, 39, 39);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
}
.home-row20 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-headers15 {
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-text214 {
  fill: #1a1a1a;
  color: rgb(26, 26, 26);
  width: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #313133;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.home-text216 {
  color: var(--dl-color-success-300);
}
.home-plans15 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-row21 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: #313133;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
}
.home-text217 {
  color: rgb(39, 39, 39);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
}
.home-text218 {
  color: rgb(39, 39, 39);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
}
.home-text219 {
  color: rgb(39, 39, 39);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
}
.home-row22 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-headers16 {
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-text220 {
  fill: #1a1a1a;
  color: rgb(26, 26, 26);
  width: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #313133;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.home-text222 {
  color: var(--dl-color-success-300);
}
.home-plans16 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-row23 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: #313133;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
}
.home-text223 {
  color: rgb(39, 39, 39);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
}
.home-text224 {
  color: rgb(39, 39, 39);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
}
.home-text225 {
  color: rgb(39, 39, 39);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
}
.home-row24 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-headers17 {
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-text226 {
  fill: #1a1a1a;
  color: rgb(26, 26, 26);
  width: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #313133;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.home-plans17 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-row25 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: #313133;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
}
.home-text227 {
  color: rgb(39, 39, 39);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
}
.home-text228 {
  color: rgb(39, 39, 39);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
}
.home-text229 {
  color: rgb(39, 39, 39);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
}
.home-row26 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-headers18 {
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-text230 {
  fill: #1a1a1a;
  color: rgb(26, 26, 26);
  width: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #313133;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.home-plans18 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-row27 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: #313133;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
}
.home-row28 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-headers19 {
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-text231 {
  fill: #1a1a1a;
  color: rgb(26, 26, 26);
  width: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #313133;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.home-plans19 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-row29 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: #313133;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
}
.home-text232 {
  color: rgb(39, 39, 39);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
}
.home-text233 {
  color: rgb(39, 39, 39);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
}
.home-row30 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-headers20 {
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-text234 {
  fill: #1a1a1a;
  color: rgb(26, 26, 26);
  width: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #313133;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.home-plans20 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-row31 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: #313133;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
}
.home-text235 {
  color: rgb(39, 39, 39);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
}
.home-text236 {
  color: rgb(39, 39, 39);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
}
.home-row32 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-headers21 {
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-text237 {
  fill: #1a1a1a;
  color: rgb(26, 26, 26);
  width: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #313133;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.home-plans21 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-row33 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: #313133;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
}
.home-row34 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-headers22 {
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-text238 {
  fill: #1a1a1a;
  color: rgb(26, 26, 26);
  width: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #313133;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.home-plans22 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-row35 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: #313133;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
}
.home-row36 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-headers23 {
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-text239 {
  fill: #1a1a1a;
  color: rgb(26, 26, 26);
  width: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #313133;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.home-plans23 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-row37 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: #313133;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
}
.home-row38 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-headers24 {
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-text240 {
  fill: #1a1a1a;
  color: rgb(26, 26, 26);
  width: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #313133;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.home-plans24 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-row39 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: #313133;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
}
.home-data {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.home-header-container5 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-header28 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-row40 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-heading22 {
  fill: #191919;
  color: rgb(25, 25, 25);
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
}
.home-caption28 {
  color: rgb(124, 124, 128);
  width: 100%;
  font-size: 24px;
  max-width: 800px;
  line-height: 36px;
}
.home-content21 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.home-image23 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-image24 {
  width: 440px;
  height: 436px;
  object-fit: cover;
}
.home-accordion {
  width: 100%;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.home-element10 {
  transition: 0.3s;
}

.home-header29 {
  fill: #191919;
  color: rgb(25, 25, 25);
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  padding-top: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-unit);
}
.home-content23 {
  line-height: 28px;
}
.home-header30 {
  fill: #191919;
  color: rgb(25, 25, 25);
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  padding-top: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-unit);
}
.home-content25 {
  line-height: 28px;
}
.home-header31 {
  fill: #191919;
  color: rgb(25, 25, 25);
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  padding-top: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-unit);
}
.home-content27 {
  line-height: 28px;
}
.home-header32 {
  fill: #191919;
  color: rgb(25, 25, 25);
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  padding-top: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-unit);
}
.home-content29 {
  line-height: 28px;
}
.home-container25 {
  display: contents;
}
.home-faq {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 160px;
}
.home-header33 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-heading23 {
  color: #191919;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
}
.home-text247 {
  font-style: italic;
  font-family: "Playfair Display";
  font-weight: 500;
}
.home-content30 {
  gap: 120px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.home-column1 {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-element14 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.home-header34 {
  color: rgb(25, 25, 25);
}
.home-content31 {
  color: rgb(124, 124, 128);
  width: 100%;
  display: flex;
  max-width: 560px;
  line-height: 24px;
}
.home-element15 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.home-header35 {
  color: rgb(25, 25, 25);
}
.home-content32 {
  color: rgb(124, 124, 128);
  width: 100%;
  display: flex;
  max-width: 560px;
  line-height: 24px;
}
.home-element16 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.home-header36 {
  color: rgb(25, 25, 25);
}
.home-content33 {
  color: rgb(124, 124, 128);
  width: 100%;
  display: flex;
  max-width: 560px;
  line-height: 24px;
}
.home-column2 {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-element17 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.home-header37 {
  color: rgb(25, 25, 25);
}
.home-content34 {
  color: rgb(124, 124, 128);
  width: 100%;
  display: flex;
  max-width: 560px;
  line-height: 24px;
}
.home-element18 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.home-header38 {
  color: rgb(25, 25, 25);
}
.home-content35 {
  color: rgb(124, 124, 128);
  width: 100%;
  display: flex;
  max-width: 560px;
  line-height: 24px;
}
.home-element19 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.home-header39 {
  color: rgb(25, 25, 25);
}
.home-content36 {
  color: rgb(124, 124, 128);
  width: 100%;
  display: flex;
  max-width: 560px;
  line-height: 24px;
}
.home-create2 {
  gap: var(--dl-space-space-fourunits);
  width: 100%;
  display: flex;
  padding: 0px;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-end;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
}
.home-content37 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 120px;
  flex-direction: column;
  padding-bottom: 120px;
  justify-content: center;
  background-color: #2684fc;
}
.home-header40 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-heading24 {
  color: rgb(255, 255, 255);
  font-size: 100px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
}
.home-text251 {
  color: rgb(255, 255, 255);
  font-size: 36px;
  font-style: normal;
  text-align: center;
  font-weight: 300;
}
.home-container26 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-link24 {
  fill: var(--dl-color-primary-300);
  color: var(--dl-color-primary-300);
  width: 259px;
  border-radius: 10px;
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.home-text252 {
  fill: #2684fc;
  color: rgb(38, 132, 252);
  font-size: 24px;
}
.home-link25 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  width: 259px;
  border-color: var(--dl-color-gray-white);
  border-width: 1px;
  border-radius: 10px;
  text-decoration: none;
  background-color: #2684fc;
  text-align: center;
}
.home-text255 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  font-size: 20px;
  text-align: center;
}
.home-text258 {
  fill: var(--dl-color-gray-white);
  color: rgba(255, 255, 255, 0.71);
  margin-top: 0px;
}
.home-footer {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.home-content38 {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  border-color: #313133;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  border-top-width: 0px;
}
.home-main {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.home-branding2 {
  gap: var(--dl-space-space-twounits);
  width: 400px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-image25 {
  width: 120px;
  object-fit: cover;
}
.home-text259 {
  color: rgb(124, 124, 128);
  line-height: 24px;
}
.home-links {
  gap: 120px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.home-column3 {
  gap: var(--dl-space-space-threeunits);
  color: white;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-header41 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
  font-size: 24px;
  font-style: italic;
  font-family: Playfair Display;
  font-weight: 500;
}
.home-list1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-link26 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.home-link26:hover {
  opacity: 0.5;
}
.home-link27 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.home-link27:hover {
  opacity: 0.5;
}
.home-link28 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.home-link28:hover {
  opacity: 0.5;
}
.home-link29 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.home-link29:hover {
  opacity: 0.5;
}
.home-link30 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.home-link30:hover {
  opacity: 0.5;
}
.home-link31 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.home-link31:hover {
  opacity: 0.5;
}
.home-column4 {
  gap: var(--dl-space-space-threeunits);
  color: white;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-header42 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
  font-size: 24px;
  font-style: italic;
  font-family: Playfair Display;
  font-weight: 500;
}
.home-list2 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-link32 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.home-link32:hover {
  opacity: 0.5;
}
.home-link33 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.home-link33:hover {
  opacity: 0.5;
}
.home-link34 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.home-link34:hover {
  opacity: 0.5;
}
.home-link35 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.home-link35:hover {
  opacity: 0.5;
}
.home-link36 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.home-link36:hover {
  opacity: 0.5;
}
.home-link37 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.home-link37:hover {
  opacity: 0.5;
}
.home-link38 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.home-link38:hover {
  opacity: 0.5;
}
.home-link39 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.home-link39:hover {
  opacity: 0.5;
}
.home-bottom {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-text274 {
  color: rgb(124, 124, 128);
}
.home-button5 {
  display: flex;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-fiveunits);
  border-radius: 0px;
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  background-color: #2684fc;
  text-align: center;
}
.home-image26 {
  width: 21px;
  object-fit: cover;
}
.home-container28 {
  display: contents;
}
@media (max-width: 1200px) {
  .home-register {
    fill: var(--dl-color-gray-black);
    color: var(--dl-color-gray-black);
    font-weight: 700;
    border-radius: 10px;
    background-color: var(--dl-color-gray-white);
  }
  .home-get-started {
    padding-top: var(--dl-space-space-sixunits);
  }
  .home-container13 {
    gap: var(--dl-space-space-halfunit);
  }
  .home-text118 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .home-text119 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .home-text120 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .home-text121 {
    color: var(--dl-color-gray-white);
    font-style: italic;
    font-weight: 400;
    background-color: rgb(38, 132, 252);
  }
  .home-text122 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .home-text123 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .home-text124 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .home-text125 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .home-text126 {
    color: var(--dl-color-gray-white);
    font-style: italic;
    font-weight: 400;
    background-color: rgb(212, 82, 48);
  }
  .home-text127 {
    color: var(--dl-color-gray-white);
    font-style: italic;
    font-weight: 400;
    background-color: rgb(212, 82, 48);
  }
  .home-text128 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .home-text129 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .home-text130 {
    color: var(--dl-color-gray-white);
    font-style: italic;
    font-weight: 400;
    background-color: rgb(222, 173, 28);
  }
  .home-text131 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .home-text132 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .home-text133 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .home-text134 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .home-text135 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .home-text136 {
    color: var(--dl-color-gray-white);
    font-style: italic;
    font-weight: 400;
    background-color: rgb(0, 174, 69);
  }
  .home-text137 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .home-text138 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .home-text139 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .home-text140 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .home-text141 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .home-text142 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .home-text143 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .home-text144 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .home-text145 {
    color: var(--dl-color-gray-black);
    font-size: 36px;
    font-style: italic;
    font-family: Playfair Display;
    font-weight: 300;
    line-height: 42px;
  }
  .home-text146 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .home-text147 {
    color: var(--dl-color-gray-black);
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
  }
  .home-text151 {
    color: rgb(124, 124, 128);
  }
  .home-customer {
    padding-left: 0px;
  }
  .home-heading17 {
    padding-bottom: 0px;
  }
  .home-text157 {
    font-style: italic;
    font-family: Playfair Display;
  }
  .home-text159 {
    fill: var(--dl-color-gray-500);
    color: var(--dl-color-gray-500);
    font-size: 24px;
    margin-top: var(--dl-space-space-unit);
    line-height: 36px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-text192 {
    font-size: 18px;
  }
  .home-caption27 {
    color: rgb(124, 124, 128);
  }
  .home-text211 {
    color: rgb(39, 39, 39);
  }
  .home-text217 {
    color: rgb(39, 39, 39);
  }
  .home-text223 {
    color: rgb(39, 39, 39);
  }
  .home-image24 {
    margin-right: var(--dl-space-space-threeunits);
  }
  .home-content27 {
    font-size: 22px;
    line-height: 28px;
  }
  .home-text259 {
    color: rgb(124, 124, 128);
    line-height: 24px;
  }
  .home-link27 {
    color: var(--dl-color-gray-black);
  }
  .home-link28 {
    color: var(--dl-color-gray-black);
    font-size: 16px;
    line-height: 32px;
    text-decoration: none;
  }
  .home-link29 {
    color: var(--dl-color-gray-black);
  }
  .home-link30 {
    color: var(--dl-color-gray-black);
  }
  .home-link32 {
    color: var(--dl-color-gray-black);
    font-size: 16px;
    line-height: 32px;
    text-decoration: none;
  }
  .home-link33 {
    color: var(--dl-color-gray-black);
    font-size: 16px;
    line-height: 32px;
    text-decoration: none;
  }
  .home-link35 {
    color: var(--dl-color-gray-black);
    font-size: 16px;
    line-height: 32px;
    text-decoration: none;
  }
  .home-link37 {
    color: var(--dl-color-gray-black);
    font-size: 16px;
    line-height: 32px;
    text-decoration: none;
  }
  .home-link38 {
    color: var(--dl-color-gray-black);
  }
  .home-link39 {
    color: var(--dl-color-gray-black);
  }
}
@media (max-width: 991px) {
  .home-hero {
    padding-left: var(--dl-space-space-oneandhalfunits);
  }
  .home-hero-content {
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-link16 {
    border-color: rgba(255, 255, 255, 0.69);
    border-width: 1px;
    background-color: rgba(255, 255, 255, 0.05);
  }
  .home-text103 {
    color: #ffffff;
  }
  .home-get-started {
    justify-content: center;
  }
  .home-header-container2 {
    align-self: center;
    align-items: center;
  }
  .home-header12 {
    align-items: center;
  }
  .home-heading11 {
    color: rgb(25, 25, 25);
    font-size: 60px;
    font-style: normal;
    font-weight: 400;
  }
  .home-caption11 {
    color: rgb(124, 124, 128);
    font-size: 24px;
    align-self: center;
    text-align: center;
    line-height: 36px;
  }
  .home-container13 {
    gap: var(--dl-space-space-halfunit);
  }
  .home-button3 {
    fill: #2684fc;
    color: #2684fc;
    border-color: #2684fc;
    border-width: 1px;
    background-color: #ffffff;
    text-align: center;
  }
  .home-image12 {
    align-self: center;
  }
  .home-slider1 {
    gap: var(--dl-space-space-twounits);
  }
  .home-image13 {
    width: 100%;
    height: 120px;
  }
  .home-content10 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    justify-content: flex-start;
  }
  .home-header13 {
    gap: var(--dl-space-space-halfunit);
    width: 100%;
  }
  .home-heading12 {
    font-size: 24px;
  }
  .home-caption12 {
    max-width: 600px;
  }
  .home-image15 {
    width: 100%;
    height: 120px;
  }
  .home-content11 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    justify-content: flex-start;
  }
  .home-header14 {
    gap: var(--dl-space-space-halfunit);
    width: 100%;
  }
  .home-heading13 {
    font-size: 24px;
  }
  .home-caption14 {
    max-width: 600px;
  }
  .home-image17 {
    width: 100%;
    height: 120px;
  }
  .home-content12 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    justify-content: flex-start;
  }
  .home-header15 {
    gap: var(--dl-space-space-halfunit);
    width: 100%;
  }
  .home-heading14 {
    font-size: 24px;
  }
  .home-caption16 {
    fill: var(--dl-color-gray-white);
    color: var(--dl-color-gray-white);
    max-width: 600px;
  }
  .home-image19 {
    width: 100%;
    height: 120px;
  }
  .home-content13 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    justify-content: flex-start;
  }
  .home-header16 {
    gap: var(--dl-space-space-halfunit);
    width: 100%;
  }
  .home-heading15 {
    font-size: 24px;
  }
  .home-caption18 {
    fill: var(--dl-color-gray-white);
    color: var(--dl-color-gray-white);
    max-width: 600px;
  }
  .home-selector {
    gap: var(--dl-space-space-twounits);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  .home-content14 {
    padding-top: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .home-stat1 {
    width: 100%;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .home-stat2 {
    width: 100%;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .home-stat3 {
    width: 100%;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-content15 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    justify-content: flex-start;
  }
  .home-objectives-list {
    padding-right: 0px;
  }
  .home-objective2 {
    flex: 1;
  }
  .home-objective3 {
    flex: 1;
  }
  .home-customer {
    max-width: 1400px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .home-header21 {
    max-width: 100%;
  }
  .home-heading17 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .home-quote1 {
    gap: var(--dl-space-space-oneandhalfunits);
    width: 100%;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .home-quote2 {
    gap: var(--dl-space-space-oneandhalfunits);
    width: 100%;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .home-quote3 {
    gap: var(--dl-space-space-oneandhalfunits);
    width: 100%;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .home-quote4 {
    gap: var(--dl-space-space-oneandhalfunits);
    width: 100%;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .home-quote5 {
    gap: var(--dl-space-space-oneandhalfunits);
    width: 100%;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .home-quote6 {
    gap: var(--dl-space-space-oneandhalfunits);
    width: 100%;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .home-controls2 {
    max-width: 100%Width;
    padding-left: 0px;
    padding-right: 0px;
  }
  .home-previous2 {
    display: none;
  }
  .home-next2 {
    display: none;
  }
  .home-growth {
    flex-direction: column;
  }
  .home-testimonial1 {
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-text169 {
    color: var(--dl-color-gray-500);
    max-width: 560px;
  }
  .home-images1 {
    padding-top: 0px;
  }
  .home-square1 {
    top: -20px;
    right: -20px;
  }
  .home-image21 {
    margin-top: 0px;
  }
  .home-experience {
    align-items: flex-start;
    flex-direction: column-reverse;
  }
  .home-text182 {
    color: var(--dl-color-gray-500);
  }
  .home-create1 {
    padding: 0px;
  }
  .home-content20 {
    gap: var(--dl-space-space-oneandhalfunits);
    padding-top: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-heading20 {
    font-size: 50px;
  }
  .home-link22 {
    width: 219px;
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-text189 {
    font-size: 16px;
  }
  .home-link23 {
    width: 219px;
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-text192 {
    font-size: 16px;
  }
  .home-headers10 {
    flex: 0;
  }
  .home-text198 {
    color: rgba(255, 255, 255, 0);
    width: 0px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
  }
  .home-row11 {
    padding-right: 0px;
    justify-content: center;
  }
  .home-row12 {
    flex-direction: column;
  }
  .home-headers11 {
    flex: 0 0 auto;
    width: 100%;
  }
  .home-text202 {
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
    border-bottom-width: 0px;
  }
  .home-plans11 {
    width: 100%;
  }
  .home-row13 {
    padding-right: 0px;
  }
  .home-row14 {
    flex-direction: column;
  }
  .home-headers12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .home-text203 {
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
    border-bottom-width: 0px;
  }
  .home-plans12 {
    width: 100%;
  }
  .home-row15 {
    padding-right: 0px;
  }
  .home-row16 {
    flex-direction: column;
  }
  .home-headers13 {
    flex: 0 0 auto;
    width: 100%;
  }
  .home-text204 {
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
    border-bottom-width: 0px;
  }
  .home-plans13 {
    width: 100%;
  }
  .home-row17 {
    padding-right: 0px;
  }
  .home-row18 {
    flex-direction: column;
  }
  .home-headers14 {
    flex: 0 0 auto;
    width: 100%;
  }
  .home-text208 {
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
    border-bottom-width: 0px;
  }
  .home-plans14 {
    width: 100%;
  }
  .home-row19 {
    padding-right: 0px;
  }
  .home-row20 {
    flex-direction: column;
  }
  .home-headers15 {
    flex: 0 0 auto;
    width: 100%;
  }
  .home-text214 {
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
    border-bottom-width: 0px;
  }
  .home-plans15 {
    width: 100%;
  }
  .home-row21 {
    padding-right: 0px;
  }
  .home-row22 {
    flex-direction: column;
  }
  .home-headers16 {
    flex: 0 0 auto;
    width: 100%;
  }
  .home-text220 {
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
    border-bottom-width: 0px;
  }
  .home-plans16 {
    width: 100%;
  }
  .home-row23 {
    padding-right: 0px;
  }
  .home-row24 {
    flex-direction: column;
  }
  .home-headers17 {
    flex: 0 0 auto;
    width: 100%;
  }
  .home-text226 {
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
    border-bottom-width: 0px;
  }
  .home-plans17 {
    width: 100%;
  }
  .home-row25 {
    padding-right: 0px;
  }
  .home-row26 {
    flex-direction: column;
  }
  .home-headers18 {
    flex: 0 0 auto;
    width: 100%;
  }
  .home-text230 {
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
    border-bottom-width: 0px;
  }
  .home-plans18 {
    width: 100%;
  }
  .home-row27 {
    padding-right: 0px;
  }
  .home-row28 {
    flex-direction: column;
  }
  .home-headers19 {
    flex: 0 0 auto;
    width: 100%;
  }
  .home-text231 {
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
    border-bottom-width: 0px;
  }
  .home-plans19 {
    width: 100%;
  }
  .home-row29 {
    padding-right: 0px;
  }
  .home-row30 {
    flex-direction: column;
  }
  .home-headers20 {
    flex: 0 0 auto;
    width: 100%;
  }
  .home-text234 {
    width: 100%;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
    border-bottom-width: 0px;
  }
  .home-plans20 {
    width: 100%;
  }
  .home-row31 {
    padding-right: 0px;
  }
  .home-row32 {
    flex-direction: column;
  }
  .home-headers21 {
    flex: 0 0 auto;
    width: 100%;
  }
  .home-text237 {
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
    border-bottom-width: 0px;
  }
  .home-plans21 {
    width: 100%;
  }
  .home-row33 {
    padding-right: 0px;
  }
  .home-row34 {
    flex-direction: column;
  }
  .home-headers22 {
    flex: 0 0 auto;
    width: 100%;
  }
  .home-text238 {
    width: 100%;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
    border-bottom-width: 0px;
  }
  .home-plans22 {
    width: 100%;
  }
  .home-row35 {
    padding-right: 0px;
  }
  .home-row36 {
    flex-direction: column;
  }
  .home-headers23 {
    flex: 0 0 auto;
    width: 100%;
  }
  .home-text239 {
    width: 100%;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
    border-bottom-width: 0px;
  }
  .home-plans23 {
    width: 100%;
  }
  .home-row37 {
    padding-right: 0px;
  }
  .home-row38 {
    flex-direction: column;
  }
  .home-headers24 {
    flex: 0 0 auto;
    width: 100%;
  }
  .home-text240 {
    width: 100%;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
    border-bottom-width: 0px;
  }
  .home-plans24 {
    width: 100%;
  }
  .home-row39 {
    padding-right: 0px;
  }
  .home-heading22 {
    color: var(--dl-color-gray-black);
  }
  .home-content21 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column-reverse;
    justify-content: flex-start;
  }
  .home-image24 {
    margin-right: 0px;
    padding-right: 0px;
  }
  .home-header29 {
    font-size: 24px;
  }
  .home-header30 {
    font-size: 24px;
  }
  .home-header31 {
    font-size: 24px;
  }
  .home-header32 {
    font-size: 24px;
  }
  .home-faq {
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .home-content30 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    justify-content: flex-start;
  }
  .home-column1 {
    max-width: 100%;
  }
  .home-header34 {
    font-size: 24px;
  }
  .home-content31 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-header35 {
    font-size: 24px;
  }
  .home-content32 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-header36 {
    font-size: 24px;
  }
  .home-content33 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-column2 {
    max-width: 100%;
  }
  .home-header37 {
    font-size: 24px;
  }
  .home-content34 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-header38 {
    font-size: 24px;
  }
  .home-content35 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-header39 {
    font-size: 24px;
  }
  .home-content36 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-create2 {
    padding: 0px;
  }
  .home-content37 {
    gap: var(--dl-space-space-oneandhalfunits);
    padding-top: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-heading24 {
    font-size: 50px;
  }
  .home-link24 {
    width: 219px;
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-text252 {
    font-size: 16px;
  }
  .home-link25 {
    width: 219px;
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
    text-align: center;
  }
  .home-text255 {
    font-size: 16px;
  }
  .home-content38 {
    border-top-width: 0px;
  }
  .home-main {
    gap: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .home-branding2 {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .home-burger-menu {
    display: flex;
  }
  .home-hamburger {
    background-color: rgba(255, 255, 255, 0.3);
  }
  .home-image11 {
    width: 200px;
  }
  .home-heading10 {
    font-size: 120px;
  }
  .home-caption10 {
    font-size: 42px;
  }
  .home-get-started {
    padding: var(--dl-space-space-fourunits);
  }
  .home-heading11 {
    font-size: 36px;
    line-height: 42px;
  }
  .home-caption11 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-container13 {
    gap: var(--dl-space-space-halfunit);
    flex-direction: column;
  }
  .home-slides {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-slide-titles {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-slider1 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-slider-track {
    align-items: stretch;
  }
  .home-heading12 {
    font-size: 18px;
  }
  .home-caption12 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-caption13 {
    font-size: 14px;
  }
  .home-text114 {
    font-size: 14px;
  }
  .home-icon19 {
    width: 11px;
    height: 11px;
  }
  .home-heading13 {
    font-size: 18px;
  }
  .home-caption14 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-caption15 {
    font-size: 14px;
  }
  .home-icon21 {
    width: 11px;
    height: 11px;
  }
  .home-heading14 {
    font-size: 18px;
  }
  .home-caption16 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-caption17 {
    font-size: 14px;
  }
  .home-icon23 {
    width: 11px;
    height: 11px;
  }
  .home-heading15 {
    font-size: 18px;
  }
  .home-caption18 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-caption19 {
    font-size: 14px;
  }
  .home-icon25 {
    width: 11px;
    height: 11px;
  }
  .home-previous1 {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-icon27 {
    width: 14px;
    height: 14px;
  }
  .home-next1 {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-icon29 {
    width: 14px;
    height: 14px;
  }
  .home-slider2 {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-heading16 {
    font-size: 40px;
  }
  .home-caption20 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-text118 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .home-text119 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .home-text120 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .home-text121 {
    color: var(--dl-color-gray-white);
    font-style: italic;
    font-weight: 400;
    background-color: rgb(38, 132, 252);
  }
  .home-text122 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .home-text123 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .home-text124 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .home-text125 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .home-text126 {
    color: var(--dl-color-gray-white);
    font-style: italic;
    font-weight: 400;
    background-color: rgb(212, 82, 48);
  }
  .home-text127 {
    color: var(--dl-color-gray-white);
    font-style: italic;
    font-weight: 400;
    background-color: rgb(212, 82, 48);
  }
  .home-text128 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .home-text129 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .home-text130 {
    color: var(--dl-color-gray-white);
    font-style: italic;
    font-weight: 400;
    background-color: rgb(222, 173, 28);
  }
  .home-text131 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .home-text132 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .home-text133 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .home-text134 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .home-text135 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .home-text136 {
    color: var(--dl-color-gray-white);
    font-style: italic;
    font-weight: 400;
    background-color: rgb(0, 174, 69);
  }
  .home-text137 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .home-text138 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .home-text139 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .home-text140 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .home-text141 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .home-text142 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .home-text143 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .home-text144 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .home-text145 {
    color: var(--dl-color-gray-black);
    font-size: 36px;
    font-style: italic;
    font-family: Playfair Display;
    font-weight: 300;
    line-height: 42px;
  }
  .home-text146 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .home-text147 {
    color: var(--dl-color-gray-black);
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
  }
  .home-caption21 {
    font-size: 14px;
  }
  .home-icon31 {
    width: 11px;
    height: 11px;
  }
  .home-statistics {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-stat1 {
    gap: var(--dl-space-space-unit);
  }
  .home-header18 {
    font-size: 36px;
    line-height: 42px;
  }
  .home-caption22 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-stat2 {
    gap: var(--dl-space-space-unit);
  }
  .home-header19 {
    font-size: 36px;
    line-height: 42px;
  }
  .home-caption23 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-stat3 {
    gap: var(--dl-space-space-unit);
  }
  .home-header20 {
    font-size: 36px;
    line-height: 42px;
  }
  .home-caption24 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-objectives {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-content15 {
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-objectives-list {
    width: 100%;
    flex-direction: column;
  }
  .home-customer {
    gap: var(--dl-space-space-threeunits);
    padding-top: var(--dl-space-space-threeunits);
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-header21 {
    padding-left: var(--dl-space-space-oneandhalfunits);
  }
  .home-text159 {
    fill: var(--dl-color-gray-black);
    color: var(--dl-color-gray-black);
  }
  .home-quote1 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-unit);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .home-author1 {
    width: 100%;
  }
  .home-name1 {
    width: 90%;
    font-size: 14px;
    line-height: 21px;
  }
  .home-location1 {
    width: 90%;
    font-size: 14px;
    line-height: 21px;
  }
  .home-quote2 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-unit);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .home-author2 {
    width: 100%;
  }
  .home-name2 {
    width: 90%;
    font-size: 14px;
    line-height: 21px;
  }
  .home-location2 {
    width: 90%;
    font-size: 14px;
    line-height: 21px;
  }
  .home-quote3 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-unit);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .home-author3 {
    width: 100%;
  }
  .home-name3 {
    width: 90%;
    font-size: 14px;
    line-height: 21px;
  }
  .home-location3 {
    width: 90%;
    font-size: 14px;
    line-height: 21px;
  }
  .home-quote4 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-unit);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .home-author4 {
    width: 100%;
  }
  .home-name4 {
    width: 90%;
    font-size: 14px;
    line-height: 21px;
  }
  .home-location4 {
    width: 90%;
    font-size: 14px;
    line-height: 21px;
  }
  .home-quote5 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-unit);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .home-author5 {
    width: 100%;
  }
  .home-name5 {
    width: 90%;
    font-size: 14px;
    line-height: 21px;
  }
  .home-location5 {
    width: 90%;
    font-size: 14px;
    line-height: 21px;
  }
  .home-quote6 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-unit);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .home-author6 {
    width: 100%;
  }
  .home-name6 {
    width: 90%;
    font-size: 14px;
    line-height: 21px;
  }
  .home-location6 {
    width: 90%;
    font-size: 14px;
    line-height: 21px;
  }
  .home-icon33 {
    width: 14px;
    height: 14px;
  }
  .home-icon35 {
    width: 14px;
    height: 14px;
  }
  .home-growth {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-content16 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-header22 {
    gap: var(--dl-space-space-unit);
  }
  .home-heading18 {
    font-size: 40px;
  }
  .home-caption25 {
    font-size: 16px;
    line-height: 24px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-text168 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-icon47 {
    width: 40px;
    height: 40px;
  }
  .home-name7 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-image21 {
    width: 100%;
  }
  .home-experience {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-square2 {
    left: -20px;
    width: 20%;
    bottom: -20px;
  }
  .home-image22 {
    width: 100%;
  }
  .home-content18 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-header24 {
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-header-container3 {
    gap: var(--dl-space-space-unit);
  }
  .home-heading19 {
    font-size: 40px;
  }
  .home-caption26 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-text181 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-author8 {
    justify-content: flex-start;
  }
  .home-icon58 {
    width: 40px;
    height: 40px;
  }
  .home-name8 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-create1 {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-content20 {
    gap: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-heading20 {
    font-size: 48px;
  }
  .home-text188 {
    font-size: 32px;
  }
  .home-container23 {
    gap: var(--dl-space-space-halfunit);
  }
  .home-text189 {
    font-size: 18px;
  }
  .home-text192 {
    font-size: 16px;
  }
  .home-comparision {
    gap: var(--dl-space-space-threeunits);
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-header-container4 {
    gap: var(--dl-space-space-unit);
  }
  .home-header27 {
    gap: var(--dl-space-space-unit);
    align-items: center;
  }
  .home-heading21 {
    font-size: 40px;
  }
  .home-caption27 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-text199 {
    font-size: 12px;
    line-height: 14px;
  }
  .home-text200 {
    font-size: 12px;
    line-height: 14px;
  }
  .home-text201 {
    font-size: 12px;
    line-height: 14px;
  }
  .home-text202 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-text203 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-text204 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-text205 {
    line-height: 21px;
  }
  .home-text206 {
    line-height: 21px;
  }
  .home-text207 {
    line-height: 21px;
  }
  .home-text208 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-text211 {
    line-height: 21px;
  }
  .home-text212 {
    line-height: 21px;
  }
  .home-text213 {
    line-height: 21px;
  }
  .home-text214 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-text217 {
    line-height: 21px;
  }
  .home-text218 {
    line-height: 21px;
  }
  .home-text219 {
    line-height: 21px;
  }
  .home-text220 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-text223 {
    line-height: 21px;
  }
  .home-text224 {
    line-height: 21px;
  }
  .home-text225 {
    line-height: 21px;
  }
  .home-text226 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-text227 {
    line-height: 21px;
  }
  .home-text228 {
    line-height: 21px;
  }
  .home-text229 {
    line-height: 21px;
  }
  .home-text230 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-text231 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-text232 {
    line-height: 21px;
  }
  .home-text233 {
    line-height: 21px;
  }
  .home-text234 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-text235 {
    line-height: 21px;
  }
  .home-text236 {
    line-height: 21px;
  }
  .home-text237 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-text238 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-text239 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-text240 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-data {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-heading22 {
    font-size: 36px;
  }
  .home-caption28 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-image23 {
    width: 100%;
  }
  .home-image24 {
    width: 100%;
    padding-right: 0px;
  }
  .home-faq {
    gap: var(--dl-space-space-threeunits);
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-header33 {
    gap: var(--dl-space-space-unit);
    align-items: center;
  }
  .home-heading23 {
    font-size: 36px;
  }
  .home-column1 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-element14 {
    gap: var(--dl-space-space-halfunit);
    padding-top: 0px;
    padding-right: 00px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
  }
  .home-header34 {
    font-size: 16px;
    line-height: 20px;
  }
  .home-element15 {
    gap: var(--dl-space-space-halfunit);
    padding-top: 0px;
    padding-right: 00px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
  }
  .home-header35 {
    font-size: 16px;
    line-height: 20px;
  }
  .home-element16 {
    gap: var(--dl-space-space-halfunit);
    padding-top: 0px;
    padding-right: 00px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
  }
  .home-header36 {
    font-size: 16px;
    line-height: 20px;
  }
  .home-column2 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-element17 {
    gap: var(--dl-space-space-halfunit);
    padding-top: 0px;
    padding-right: 00px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
  }
  .home-header37 {
    font-size: 16px;
    line-height: 20px;
  }
  .home-element18 {
    gap: var(--dl-space-space-halfunit);
    padding-top: 0px;
    padding-right: 00px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
  }
  .home-header38 {
    font-size: 16px;
    line-height: 20px;
  }
  .home-element19 {
    gap: var(--dl-space-space-halfunit);
    padding-top: 0px;
    padding-right: 00px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
  }
  .home-header39 {
    font-size: 16px;
    line-height: 20px;
  }
  .home-create2 {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-content37 {
    gap: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-heading24 {
    font-size: 48px;
  }
  .home-text251 {
    font-size: 32px;
  }
  .home-container26 {
    gap: var(--dl-space-space-halfunit);
  }
  .home-text252 {
    font-size: 18px;
  }
  .home-link25 {
    fill: var(--dl-color-gray-white);
    color: var(--dl-color-gray-white);
    border-color: var(--dl-color-gray-white);
    border-width: 1px;
    background-color: #2684fc;
  }
  .home-text255 {
    fill: var(--dl-color-gray-white);
    color: var(--dl-color-gray-white);
    font-size: 16px;
  }
  .home-footer {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-links {
    gap: var(--dl-space-space-fiveunits);
    flex-direction: row;
  }
}
@media (max-width: 479px) {
  .home-branding1 {
    width: 93px;
    padding-top: var(--dl-space-space-unit);
  }
  .home-hamburger {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: rgba(255, 255, 255, 0.3);
  }
  .home-icon10 {
    width: 30px;
  }
  .home-mobile-menu {
    padding: 16px;
  }
  .home-hero-content {
    gap: var(--dl-space-space-threeunits);
    align-self: center;
    padding-top: var(--dl-space-space-threeunits);
    justify-content: flex-start;
  }
  .home-header-container1 {
    align-items: flex-start;
    justify-content: center;
  }
  .home-image11 {
    width: 200px;
  }
  .home-heading10 {
    font-size: 90px;
  }
  .home-caption10 {
    color: rgb(255, 255, 255);
    font-size: 36px;
    line-height: 42px;
  }
  .home-link16 {
    width: 100%;
    align-self: flex-start;
    text-align: center;
  }
  .home-text103 {
    font-size: 24px;
  }
  .home-text106 {
    fill: var(--dl-color-gray-white);
    color: rgba(255, 255, 255, 0.57);
    font-size: 18px;
    align-self: center;
    margin-top: -30px;
  }
  .home-heading11 {
    align-self: center;
    text-align: center;
  }
  .home-caption11 {
    color: rgb(124, 124, 128);
    font-size: 24px;
    align-self: center;
    line-height: 36px;
  }
  .home-container13 {
    gap: var(--dl-space-space-halfunit);
    flex-direction: column;
  }
  .home-image12 {
    align-self: center;
  }
  .home-slide-titles {
    display: none;
  }
  .home-slider-container {
    padding-top: 0px;
  }
  .home-slide1 {
    align-items: flex-start;
    justify-content: flex-start;
  }
  .home-heading12 {
    font-size: 22px;
  }
  .home-caption12 {
    color: rgb(255, 255, 255);
    font-size: 22px;
    line-height: 24px;
  }
  .home-slide2 {
    align-items: flex-start;
    justify-content: flex-start;
  }
  .home-heading13 {
    font-size: 22px;
  }
  .home-caption14 {
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
  }
  .home-heading14 {
    font-size: 22px;
  }
  .home-caption16 {
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
  }
  .home-heading15 {
    font-size: 22px;
  }
  .home-caption18 {
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
  }
  .home-heading16 {
    font-size: 38px;
    line-height: 48px;
  }
  .home-caption20 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-text118 {
    font-style: normal;
  }
  .home-text119 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .home-text120 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .home-text121 {
    color: var(--dl-color-gray-white);
    font-style: italic;
    font-weight: 400;
    background-color: rgb(38, 132, 252);
  }
  .home-text122 {
    font-style: normal;
  }
  .home-text123 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .home-text124 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .home-text125 {
    font-style: normal;
  }
  .home-text126 {
    color: var(--dl-color-gray-white);
    font-style: italic;
    font-weight: 400;
    background-color: rgb(212, 82, 48);
  }
  .home-text127 {
    color: var(--dl-color-gray-white);
    font-style: italic;
    font-weight: 400;
    background-color: rgb(212, 82, 48);
  }
  .home-text128 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .home-text129 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .home-text130 {
    color: var(--dl-color-gray-white);
    font-style: italic;
    font-weight: 400;
    background-color: rgb(222, 173, 28);
  }
  .home-text131 {
    font-style: normal;
  }
  .home-text132 {
    font-style: normal;
  }
  .home-text133 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .home-text134 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .home-text135 {
    font-style: normal;
  }
  .home-text136 {
    color: var(--dl-color-gray-white);
    font-style: italic;
    font-weight: 400;
    background-color: rgb(0, 174, 69);
  }
  .home-text137 {
    font-style: normal;
  }
  .home-text138 {
    font-style: normal;
  }
  .home-text139 {
    font-style: normal;
  }
  .home-text140 {
    font-style: normal;
  }
  .home-text141 {
    font-style: normal;
  }
  .home-text142 {
    font-style: normal;
  }
  .home-text143 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .home-text144 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 300;
  }
  .home-text145 {
    font-size: 38px;
    font-style: italic;
    font-family: Playfair Display;
    font-weight: 400;
    line-height: 42px;
  }
  .home-text146 {
    font-size: 38px;
    font-style: normal;
    font-family: Playfair Display;
    font-weight: 400;
  }
  .home-text147 {
    font-size: 18px;
    font-style: normal;
    line-height: 32px;
  }
  .home-header18 {
    color: #191919;
  }
  .home-header19 {
    fill: #191919;
    color: #191919;
  }
  .home-header20 {
    fill: #191919;
    color: #191919;
  }
  .home-content15 {
    border-bottom-width: 1px;
  }
  .home-text149 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-text150 {
    font-size: 18px;
  }
  .home-text152 {
    font-size: 18px;
  }
  .home-text154 {
    font-size: 18px;
  }
  .home-customer {
    position: relative;
  }
  .home-heading17 {
    padding-bottom: 0px;
  }
  .home-text157 {
    font-style: italic;
    font-family: Playfair Display;
    font-weight: 500;
  }
  .home-text159 {
    fill: var(--dl-color-gray-black);
    color: var(--dl-color-gray-black);
    font-size: 28px;
    font-style: normal;
    font-weight: 300;
    line-height: 36px;
  }
  .home-container16 {
    gap: var(--dl-space-space-halfunit);
    flex-direction: row;
    justify-content: flex-start;
  }
  .home-container17 {
    flex: 1;
    width: auto;
  }
  .home-quote1 {
    padding: 0px;
  }
  .home-name1 {
    color: #272727;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
  }
  .home-location1 {
    color: #272727;
    font-size: 14px;
    line-height: 21px;
  }
  .home-container18 {
    flex: 1;
    width: auto;
  }
  .home-quote2 {
    width: auto;
    height: auto;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .home-name2 {
    color: #272727;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
  }
  .home-location2 {
    color: #272727;
    font-size: 14px;
    line-height: 21px;
  }
  .home-container19 {
    flex: 1;
    width: auto;
  }
  .home-quote3 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .home-name3 {
    color: #272727;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
  }
  .home-location3 {
    color: #272727;
    font-size: 14px;
    line-height: 21px;
  }
  .home-container20 {
    flex: 1;
    width: auto;
  }
  .home-quote4 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .home-name4 {
    color: #272727;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
  }
  .home-location4 {
    color: #272727;
    font-size: 14px;
    line-height: 21px;
  }
  .home-container21 {
    flex: 1;
    width: auto;
  }
  .home-quote5 {
    padding: 0px;
  }
  .home-name5 {
    color: #272727;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
  }
  .home-location5 {
    color: #272727;
    font-size: 14px;
    line-height: 21px;
  }
  .home-container22 {
    flex: 1;
    width: auto;
  }
  .home-quote6 {
    padding: 0px;
  }
  .home-name6 {
    color: #272727;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
  }
  .home-location6 {
    color: #272727;
    font-size: 14px;
    line-height: 21px;
  }
  .home-button4 {
    display: none;
    text-align: center;
  }
  .home-heading18 {
    color: rgb(25, 25, 25);
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
  }
  .home-caption25 {
    color: rgb(124, 124, 128);
    font-size: 22px;
    line-height: 28px;
  }
  .home-text168 {
    color: rgb(124, 124, 128);
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
  }
  .home-text169 {
    color: var(--dl-color-gray-500);
  }
  .home-heading19 {
    color: rgb(25, 25, 25);
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
  }
  .home-caption26 {
    color: rgb(124, 124, 128);
    line-height: 28px;
  }
  .home-text181 {
    color: rgb(124, 124, 128);
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
  }
  .home-name8 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-create1 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .home-content20 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-heading20 {
    color: rgb(255, 255, 255);
    font-style: normal;
    text-align: center;
    font-weight: 500;
  }
  .home-text188 {
    font-size: 28px;
  }
  .home-container23 {
    gap: var(--dl-space-space-halfunit);
  }
  .home-link22 {
    text-align: center;
    border-radius: 10px;
  }
  .home-text189 {
    fill: #2684fc;
    color: #2684fc;
  }
  .home-link23 {
    text-align: center;
    border-radius: 10px;
  }
  .home-text192 {
    fill: var(--dl-color-gray-white);
    color: var(--dl-color-gray-white);
    font-size: 16px;
  }
  .home-text195 {
    fill: var(--dl-color-gray-white);
    color: rgba(255, 255, 255, 0.72);
    margin-top: 0px;
  }
  .home-header27 {
    gap: 0px;
    flex-direction: column;
  }
  .home-heading21 {
    color: #272727;
    text-align: center;
  }
  .home-caption27 {
    font-size: 22px;
    line-height: 28px;
  }
  .home-data {
    gap: var(--dl-space-space-unit);
  }
  .home-heading22 {
    color: #272727;
    font-size: 36px;
  }
  .home-caption28 {
    font-size: 22px;
    line-height: 32px;
  }
  .home-content21 {
    flex-direction: column-reverse;
  }
  .home-image24 {
    padding-right: 0px;
  }
  .home-header29 {
    font-size: 20px;
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .home-content23 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-header30 {
    font-size: 20px;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .home-content25 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-header31 {
    font-size: 20px;
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .home-content27 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-header32 {
    font-size: 20px;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .home-content29 {
    font-size: 16px;
  }
  .home-header33 {
    gap: 0px;
    align-items: flex-start;
    flex-direction: column;
  }
  .home-create2 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .home-content37 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-heading24 {
    color: rgb(255, 255, 255);
    font-style: normal;
    text-align: center;
    font-weight: 500;
  }
  .home-text251 {
    font-size: 28px;
  }
  .home-link24 {
    text-align: center;
    border-radius: 10px;
  }
  .home-text252 {
    fill: #2684fc;
    color: #2684fc;
  }
  .home-link25 {
    text-align: center;
    border-radius: 10px;
  }
  .home-text255 {
    fill: var(--dl-color-gray-white);
    color: var(--dl-color-gray-white);
  }
  .home-text258 {
    fill: var(--dl-color-gray-white);
    color: rgba(255, 255, 255, 0.72);
    margin-top: 0px;
  }
  .home-links {
    gap: 0;
    width: 432px;
    padding-right: var(--dl-space-space-oneandhalfunits);
    flex-direction: row;
    justify-content: flex-start;
  }
  .home-column3 {
    flex: 1;
  }
  .home-column4 {
    flex: 1;
    align-items: flex-start;
  }
  .home-link37 {
    color: var(--dl-color-gray-black);
    font-size: 16px;
    line-height: 32px;
    text-decoration: none;
  }
  .home-link38 {
    color: var(--dl-color-gray-black);
    font-size: 16px;
    line-height: 32px;
    text-decoration: none;
  }
  .home-link39 {
    color: var(--dl-color-gray-black);
    font-size: 16px;
    line-height: 32px;
    text-decoration: none;
  }
  .home-bottom {
    gap: var(--dl-space-space-twounits);
    flex-direction: column;
    justify-content: center;
  }
  .home-button5 {
    justify-content: center;
  }
}
