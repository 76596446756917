.whitepaper-loading-container1 {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.whitepaper-loading-text1 {
  text-align: center;
}
.whitepaper-loading-text6 {
  margin-top: var(--dl-space-space-twounits);
}
.whitepaper-loading-container3 {
  display: contents;
}
@media(max-width: 1200px) {
  .whitepaper-loading-text1 {
    text-align: center;
  }
  .whitepaper-loading-text6 {
    margin-top: var(--dl-space-space-threeunits);
  }
}
